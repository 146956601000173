import { userReducer, UserState } from './reducers/user.reducer';
import { userStatusReducer, UserStatusState } from './reducers/user-status.reducer';
import { userTopicReducer, UserTopicState } from './reducers/topic.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface State {
  user: UserState,
  userHistory: UserStatusState,
  userTopic: UserTopicState,
}

export const reducers: ActionReducerMap<State> = {
  user: userReducer,
  userHistory: userStatusReducer,
  userTopic: userTopicReducer,
}
