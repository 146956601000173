import { Level } from "../models/level.model";

export const Levels: Level[] = [
    {
        LevelId: 1,
        Title: `Pete Needs Help!`,
        Message: `Choose a game at left to help him understand the steps he needs to take to get his <strong>A1C number</strong> where it needs to be for his optimum health.`,
        AvatarUrl: `assets/images/avatars/level-1-avatar.svg`,
        BackgroundUrl: `assets/images/avatars/level-1-bg.svg`,
        MinPoints: 0,
        MaxPoints: 0,
    },
    {
        LevelId: 2,
        Title: `Good Start!`,
        Message: `Pete is starting to get the picture of the steps he has to take to keep his <strong>A1C number</strong> where it needs to be for his optimum health. Keep playing to help him make even more progress`,
        AvatarUrl: `assets/images/avatars/level-2-avatar.svg`,
        BackgroundUrl: `assets/images/avatars/level-2-bg.svg`,
        MinPoints: 1,
        MaxPoints: 1,
    },
    {
        LevelId: 3,
        Title: `Great Job!`,
        Message: `Pete is making great progress on his <strong>A1C number</strong>. Help him keep it up and make even greater strides.`,
        AvatarUrl: `assets/images/avatars/level-3-avatar.svg`,
        BackgroundUrl: `assets/images/avatars/level-3-bg.svg`,
        MinPoints: 2,
        MaxPoints: 2,
    },
    {
        LevelId: 4,
        Title: `Contrats!`,
        Message: `Pete has his <strong>A1C number</strong> under control. Thanks to you he knows what he needs to do to maintain his health.`,
        AvatarUrl: `assets/images/avatars/level-4-avatar.svg`,
        BackgroundUrl: `assets/images/avatars/level-4-bg.svg`,
        MinPoints: 3,
        MaxPoints: 3,
    },
];