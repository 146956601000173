import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { EmailRequest, AdhocEmailRequest } from 'src/app/models';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    apiData = environment.synApi;
    activityApiData = environment.activityApi;

    constructor(
        private http: HttpClient,
    ) { }

    sendForgotPasswordEmail(emailRequest: EmailRequest, email: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.apiData.authUrl }User/ForgotPassword?username=${ email }&key=${ environment.synApi.key }`, emailRequest);
    }

    sendAdhocEmail(emailRequest: AdhocEmailRequest) {
        return this.http.post<boolean>(`${ this.apiData.authUrl }Email/Adhoc`, emailRequest);
    }
}