import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable()
export class StorageContainer {

    constructor(
        private storageMap: StorageMap,
    ) {}

    setStorageItem(key: string, item: any) {
        this.storageMap.set(key, item)
            .subscribe(() => { });
    }

    getStorageItem(key: string): Observable<any> {
        return this.storageMap.get(key);
    }

    hasStorageItem(key: string): Observable<boolean> {
        return this.storageMap.has(key);
    }

    deleteStorageItem(key: string) {
        this.storageMap.delete(key)
            .subscribe(() => { });
    }

    deleteAllStorageItems() {
        this.storageMap.clear()
            .subscribe(() => { });
    }
}