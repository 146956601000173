import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap, switchMap, filter, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthContainer } from '../containers/auth.container';
import { take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  apiData = environment.synApi;
  isRefreshingToken = false;

  constructor(
    private authContainer: AuthContainer,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clonedRequest = this.addAuthToken(request);

    return next.handle(clonedRequest).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.body.hasOwnProperty('Token')) {
            this.authContainer.updateAppToken(event.body.Token);
          }
        }
      }),
      retry(2),
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;

        if ((<HttpErrorResponse>error).status == 401) {
          if (this.isRefreshingToken) {
            console.log('is refreshing token')
            // wait on new token to register then call  request again
            return this.authContainer.appToken$.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthToken(request)))
            );
          } else {
            this.isRefreshingToken = true;

            // set token to null to force request to wait until refresh
            this.authContainer.updateAppToken(null);

            return this.authContainer.authenticateApp()
              .pipe(
                switchMap((token: string) => {
                  this.authContainer.updateAppToken(token);
                  return next.handle(this.addAuthToken(request));
                }),
                finalize(() => {
                  this.isRefreshingToken = false;
                  // this.authContainer.getAppSettings();
                })

              );
          }
        }
        else {
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            errorMessage = `Error Code: ${error.status}\nMessages: ${error.message}`;
          }

          return throwError(errorMessage);
        }
      })
    );
  }

  addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: this.authContainer.getTokenAuthString()
      }
    });
  }

}
