// TODO: Need to consistently leverage behavior subjects more accross app

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { GoogleEventsService } from './services/google-events/google-events.service';
import { environment } from '../environments/environment';
import { User, Activity } from './models';
import { LoginContainer } from './containers/login.container';
import { StorageContainer } from './containers/storage.container';
import { ActivityContainer } from './containers/activity.container';
import { filter, distinctUntilChanged, share, take } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  user$ = this.authService.user$;
  current;

  isProd = environment.production;
  public currentUser: User;
  public activityData: Activity;

  activityData$ = this.activityContainer.activityData;
  currentUser$ = this.loginContainer.currentUser;
  userExists$ = this.storageContainer.hasStorageItem('user').pipe(share());

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private googleEvent: GoogleEventsService,
    private loginContainer: LoginContainer,
    private storageContainer: StorageContainer,
    private activityContainer: ActivityContainer,
  ) {}

  trackPageRoutes() {
    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );

    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', environment.googleTags.analyticsId,
      {page_path: e.urlAfterRedirects});
      gtag('config', environment.googleTags.adsId, { page_path: e.urlAfterRedirects });
    });
  }

  ngOnInit() {
    this.subscribeToUser();
    this.subscribeToActivityData();
    this.authService.isUserLoggedIn();

    this.router.events
      .subscribe((e: NavigationEnd) => {

        window.scrollTo(0, 0);
        // console.log(this.router.routerState.snapshot.url);
        const pageTitle = this.router.routerState.snapshot.url;
        const pagePath = e.urlAfterRedirects;

        // tslint:disable-next-line:max-line-length
        // Page view manually sends to Google Anaylics as page views, since it is disabled in the gtag located in index.html. You have to disable page view in the gtag per Google
        // this.googleEvent.trackGaEvent('config', pageTitle, pagePath);

        // When user views url for Posttest/24, the AdWords Page Conversion Fires for Conversion ID# EMOYCMPTibMBEP2M2e4D
        // if (e.urlAfterRedirects.includes('posttest')) {
        //   this.googleEvent.adWordsConversion('EMOYCMPTibMBEP2M2e4D');
        // }
      });
  }

  ngOnDestroy() {
    // this.subs.forEach(s => s.unsubscribe);
  }


  subscribeToUser(): void {
    this.loginContainer.currentUser
      .pipe(distinctUntilChanged())
      .subscribe((user: User) => {
        this.currentUser = user;
        this.loginContainer.updateUser(user);
      });
  }

  subscribeToActivityData(): void {
    this.activityContainer.activityData
      .pipe(take(1))
      .subscribe((activity: Activity) => {
        this.activityData = activity;
        this.activityContainer.updateActivityData(activity);
      });

  }

  subscribeToCurrentUser() {
    return this.authService.getCurretUser().take(1).subscribe(user => {
      console.log(user);
      if (!!user.current) {
        this.router.navigate([ '/login' ]);
      }
    });

  }
}
