import { IUser } from '../../models/user.model';
import * as user from '../actions/user.action';

export interface UserState {
  current: IUser;
}

const initialState: UserState = {
  current: null
};

export function userReducer(state = initialState, action: user.Actions): UserState {
  switch (action.type) {
    case user.ActionTypes.USER_SET: return Object.assign({}, state, { current: action.payload });
    case user.ActionTypes.USER_RESET: return Object.assign({}, state, { current: null });
    default: {
      return state;
    }
  }
}
