import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { TopicData } from '../../data/topics';
import {
  IEvalRequest,
  IEvalDictionary,
  IOption,
  ITestQuestion,
  ITestRequest,
  ITestResponse,
  ITopic,
  ITopicStatusRequest,
  IRationalData
} from '../../models/topic.model';


@Injectable()
export class TopicService {
  topics = TopicData;
  rationale;
  apiInfo = environment.api;
  activityInfo = environment.activity;

  private rationaleQuestionIdSource = new BehaviorSubject<number>(null);
  currentRationaleQuestionId$ = this.rationaleQuestionIdSource.asObservable();

  private rationaleTestTypeSource = new BehaviorSubject<string>('');
  currentRationaleTestType$ = this.rationaleTestTypeSource.asObservable();

  constructor(private http: HttpClient) { }

  getTopicById(topicId: number): ITopic {
    return this.topics.find((item: ITopic) => item.TopicID === topicId);
  }

  getQuestionById(questions: any[], questionId: number): ITestQuestion {
    return questions.find(item => item.id === questionId);
  }

  submitTopicTest(
    testRequest: ITestRequest,
    token: string,
    testType: string
  ): Observable<ITestResponse> {
    let type = testType.toLowerCase();

    switch (type) {
      case 'test':
      case 'tests':
        type = 'Tests';
        break;

      case 'casestudy':
      case 'case':
        type = 'Casestudytest';
        break;

      case 'Postests':
      case 'postest':
        type = 'Posttest';
        break;

      default:
        type = 'Pretests';
        break;
    }
    // return this.http
    //   .post(
    //     this.apiInfo.url +
    //     type +
    //     '/' +
    //     this.activityInfo.id +
    //     '/' +
    //     testRequest.user.userAccountId +
    //     '?Token=' +
    //     token,
    //     JSON.stringify(testRequest),
    //     {
    //       headers: new Headers({ 'Content-Type': 'application/json' }),
    //     }
    //   )
    //   .map((res: Response) => res.json());
    return null;
  }

  submitTopicStatus(
    topicStatus: ITopicStatusRequest
  ): Observable<ITestResponse> {
    console.log('topicStatus = ', topicStatus);
    // return this.http
    //   .post(
    //     this.apiInfo.url +
    //     'Tests/Topic/' +
    //     this.activityInfo.id +
    //     '/' +
    //     topicStatus.user.userAccountID +
    //     '/' +
    //     topicStatus.TopicId +
    //     '?Token=' +
    //     topicStatus.user.Token +
    //     '&Status=' +
    //     topicStatus.Status,
    //     JSON.stringify(topicStatus),
    //     {
    //       headers: new Headers({ 'Content-Type': 'application/json' }),
    //     }
    //   )
    //   .map((res: Response) => res.json());
    return null;
  }

  calculateScore(questions: ITestQuestion[]): number {
    const questionNumber = questions.length;
    const correctAnswers = [];

    questions.map(q => {
      q.options.map(opt => {
        if (opt.selected === true && opt.correct === true) {
          correctAnswers.push(opt);
        }
      });
    });

    return correctAnswers.length / questionNumber * 100;
  }

  validateAnswer(question: ITestQuestion, questionOption: IOption): IOption[] {
    const optGroup = [];
    question.options.forEach(opt => {
      opt.correct = null;
      if (opt.id !== questionOption.id) {
        opt.selected = false;
        opt.correct = null;
      } else {
        if (opt.value === question.answerId) {
          opt.correct = true;
          question.correct = true;
        } else {
          opt.correct = false;
          question.correct = false;
        }
      }
      optGroup.push(opt);
    });

    return optGroup;
  }

  validateQuestionOptions(questions: ITestQuestion[]): ITestQuestion[] {
    questions.forEach(q => {
      q.options.forEach(opt => {
        if(opt.id === q.answerId) {
          opt.correct = true;
        } else {
          opt.correct = false;
        }
      })
    });
    return questions;
  }

  resetQuizValidation(questions: ITestQuestion[]) {
    questions.forEach(q => {
      q.correct = null;
    });

  }

  convertTopicTimeToCredits(hours: number, minutes: number) {
    hours = hours * 60;
    minutes = hours + minutes;
    return (minutes / 60).toFixed(2);
  }

  submitEvaluation(
    evalData: IEvalRequest,
    token: string
  ): Observable<ITestResponse> {
    // return this.http
    //   .post(
    //     this.apiInfo.url +
    //     'Evaluation/' +
    //     this.activityInfo.id +
    //     '/' +
    //     evalData.user.userAccountID +
    //     '?Token=' +
    //     token +
    //     '&TopicId=' +
    //     evalData.TopicId,
    //     JSON.stringify(evalData),
    //     {
    //       headers: new Headers({ 'Content-Type': 'application/json' }),
    //     }
    //   )
    //   .map((res: Response) => res.json());
    return null;
  }

  getRationaleByTestTypeAndQuestionId(topicId: number, questionId: number, testType: string) {
    const topic = this.getTopicById(topicId);

    let testQuestions: ITestQuestion[],
      testQuestion;

    const type = testType;
    switch (type) {
      case 'casestudy':
        testQuestions = topic.caseQuestions;
        testQuestion = testQuestions.find(
          question => question.id === questionId
        );
       testQuestion = testQuestion.rationale;
        break;

      case 'posttest':
        testQuestions = topic.testQuestions;
        testQuestion = testQuestions.find(
          question => question.id === questionId
        );
       testQuestion = testQuestion.rationale;

        break;
    }

    return testQuestion;
  }

  updateQuestionId(id: number) {
    this.rationaleQuestionIdSource.next(id);
  }

  resetQuestionId() {
    this.rationaleQuestionIdSource.next(0);
  }

  updateTestType(type: string) {
    this.rationaleTestTypeSource.next(type);
  }

  resetTestType() {
    this.rationaleTestTypeSource.next('');
  }

  createEvalDictionary(formData: object): IEvalDictionary[] {
    let dictionaryArray = [];
    for (let [ key, value ] of Object.entries(formData)) {
      let dictionarykey = key,
        dictionaryValue;
      if (value !== null && typeof value === 'object') {
        dictionaryValue = value[ Object.keys(value)[ 0 ] ];
      } else {
        dictionaryValue = value;
      }

      dictionaryArray.push({
        Key: dictionarykey,
        Value: dictionaryValue
      });

    }
    return dictionaryArray;
  }
}
