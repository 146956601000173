import { Injectable, Injector } from '@angular/core';
import { SimpleModalOptions, SimpleModalService } from 'ngx-simple-modal';

import { InfoModalComponent } from '../../components/modals/info-modal/info-modal.component';
import { RationaleModalComponent } from '../../components/modals/rationale-modal/rationale-modal.component';
import { ICMInformation } from '../../models/settings.model';

@Injectable()
export class ModalLauncherService {
  modalOptions: SimpleModalOptions = {
    closeOnClickOutside: true,
    closeOnEscape: true,
    bodyClass: 'modal-open',
    wrapperDefaultClasses: 'modal',
    wrapperClass: 'in',
    animationDuration: 300,
    draggable: false,
    draggableClass: null,
    autoFocus: false
  };

  constructor(public modalService: SimpleModalService, injector: Injector) {}

  launchRationaleModal(questionId: number, testType: string, content: string) {
    this.closeOtherModals();

    this.modalService.addModal(
      RationaleModalComponent,
      {
        modalLauncherService: this,
        testType: testType,
        questionId: questionId,
        content: content,
      },
      this.modalOptions
    );
  }

  launchInformationModal(content: ICMInformation[]) {
    this.closeOtherModals();

    this.modalService.addModal(
      InfoModalComponent,
      {
        modalLauncherService: this,
        content: content,
      },
      this.modalOptions
    );
  }

  private closeOtherModals() {
    try {
      this.modalService.removeAll();
    } catch (e) {}
  }
}
