import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoggerService } from 'src/app/services/logger.service';
import { StorageContainer } from 'src/app/containers/storage.container';
import { Activity, Module, UserActivity, UserModule, Level, User, Certificate } from 'src/app/models';
import { ActivityService } from '../services/activity.service';
import { Levels } from '../data/levels.data';

@Injectable()
export class ActivityContainer {

    private activityDataSubject = new BehaviorSubject<Activity>(null);
    public activityData = this.activityDataSubject.asObservable();

    private gameEndedSubject = new BehaviorSubject<boolean>(false);
    public gameEnded = this.gameEndedSubject.asObservable();

    constructor(
        private storageContainer: StorageContainer,
        private logger: LoggerService,
        private activityService: ActivityService,
    ) {
        this.setActivityData();
    }

    setActivityData() {
        this.activityService.getActivityById()
            .pipe(take(1))
            .subscribe((activity: Activity) => {
                this.updateActivityData(activity);
            });
    }

    public get activityDataValue(): Activity {
        return this.activityDataSubject.value;
    }

    updateGameEnded(isEnd: boolean) {
        this.gameEndedSubject.next(isEnd);
    }


    updateActivityData(activity: Activity) {
        this.storageContainer.setStorageItem('activityData', activity);
        this.activityDataSubject.next(activity);
    }

    getModuleByNiceName(niceName: string, modules: Module[]): Module {
        return modules.find(m => m.NiceName == niceName);
    }

    isModuleComplete(moduleId: string, userModules: UserModule[]): boolean {
        if (!userModules) return false;
        let foundModule = userModules.find(m => m.ModuleId == moduleId);       
        return foundModule && foundModule.IsComplete;
    }

    isModuleActiveAndComplete(module: Module, userModules: UserModule[]): boolean {
        if(!userModules) return false;
        let foundModule = userModules.find(m => m.ModuleId == module.ModuleId);
        return foundModule && foundModule.IsComplete && module.IsActive;
    }

    isModuleActiveAndIncomplete(module: Module, userModules: UserModule[]): boolean {
        if (!userModules) return false;
        let foundModule = userModules.find(m => m.ModuleId == module.ModuleId);
        return foundModule && !foundModule.IsComplete && module.IsActive;
    }

    getSortedLevels(): Level[] {
        return Levels.sort((x, y) => {
            return x.LevelId - y.LevelId;
        });
    }

    getUserLevel(userModules: UserModule[]): Level {
        let levels = this.getSortedLevels(),
            currentLevel = levels[0],
            completedModules = userModules.filter(m => m.IsComplete),
            completedCount = completedModules.length;
        
        for (let i = 0; i < levels.length; i++) {
            const level = levels[i];
            
            if (completedCount >= level.MinPoints && completedCount <= level.MaxPoints) {
                currentLevel = level;
                break;
            }
        }

        return currentLevel;
    }

    getUserCertificateByModuleId(moduleId: string, userModules: UserModule[]): Certificate {
        let module = userModules.find(m => m.ModuleId == moduleId);
        return module.Certificate;
    }

    getUserCertificateTypeId(user: User): number{
        if(user.UserClaims.length < 1) return 3;
        let professionClaim = user.UserClaims.find(c => c.ValueName.toLowerCase() == 'profession');

        if (!professionClaim) return 3;

        let profession = professionClaim.ValueString.toLowerCase(),
        cmeList = ['md', 'do', 'pa'],
        ceList = ['np', 'rn'];

        if(cmeList.includes(profession)) return 1;
        if(ceList.includes(profession)) return 2;

        return 3;
    }

}