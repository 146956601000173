// TODO: add certificates service

import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormControl,
  FormArray,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts
} from 'angular-2-dropdown-multiselect';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/take';
import {
  SecurityQuestions,
  Professions,
  CertificateTypes,
  EvalRankingOptions,
  PatientAverage,
  YesNoOptions
} from '../../data/settings';
import { take } from 'rxjs/operators';

import { IClientRegisterRequest, IRegisterRequest, IUser } from '../../models/user.model';
import { IUserStatus } from '../../models/user-status.model';
import { IEvalRequest, ITopic, ITopicStatusRequest } from '../../models/topic.model';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { TopicService } from '../../services/topic/topic.service';
import { CertificateService } from '../../services/certificate/certificate.service';
import { environment } from '../../../environments/environment';
import { GoogleEventsService } from '../../services/google-events/google-events.service';
import { LoginContainer } from "../../containers/login.container";
import { LoggerService } from "../../services/logger.service";
import { TrackByService } from "../../services/trackby.service";
import { ActivityContainer } from "../../containers/activity.container";
import { Subject } from "rxjs";
import { FormGroup } from '@angular/forms';

import { DynamicFormModel, DynamicFormService } from "@ng-dynamic-forms/core";

import { ActivityTest, ActivityTestResponse, QuestionResponse } from "../../models/activity-test.model";
import { SimpleMessage } from "../../models/base.model";
import { User } from "../../models/user2.model";

import { Certificate } from "../../models/certificate.model";

import { ActivityService } from "../../services/activity.service";
import { FormConverterContainer } from "./form-converter.container";
import { EvalModule1, EvalModule2, EvalModule3 } from 'src/app/data/evaluation';
import { StorageContainer } from 'src/app/containers/storage.container';

declare var require: any;

export interface ITopicStatus {
  TopicID: number;
  icon?: string;
  caseTitle: string;
  caseDescription: string;
  status: string;
}
@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',

  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnDestroy {

  evaluationSlug = 'evaluation';
  loading = false;
  submissionMessage: string;
  user: IUser;
  userStatus: IUserStatus;
  userCurrentTopicId: number;
  topicId: number;
  topics = environment.activity.topics;
  activityInfo = environment.activity;
  credits;

  evalFormGroup: UntypedFormGroup;
  professionCtrl: AbstractControl;
  specialtyCtrl: AbstractControl;
  avgPatientCtrl: AbstractControl;
  evidenceBasedCtrl: AbstractControl;
  biasFreeCtrl: AbstractControl;
  biasExplanationCtrl: AbstractControl;
  describeGLPCtrl: AbstractControl;
  evaluateGLPCtrl: AbstractControl;
  discussGLPCtrl: AbstractControl;
  relevantCtrl: AbstractControl;
  educationalCtrl: AbstractControl;
  knowledgeCtrl: AbstractControl;
  confidenceCtrl: AbstractControl;
  unansweredQuestionCtrl: AbstractControl;
  infoLearnedCtrl: AbstractControl;
  relatedTopicsCtrl: AbstractControl;
  commitmentCtrl: AbstractControl;
  commitmentDescriptionCtrl: AbstractControl;
  timeCtrl: AbstractControl;
  certificateTypeCtrl: AbstractControl;
  nameCtrl: AbstractControl;

  evidenceBasedCtrlA: AbstractControl;
  biasFreeCtrlA: AbstractControl;

  timeMultiselectSettings: IMultiSelectSettings = {
    enableSearch: false,
    closeOnSelect: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-block btn-multiselect',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
    closeOnClickOutside: true,
    autoUnselect: true,
    selectionLimit: 1,
    containerClasses: ''
  };
  timeMultiselectTextSettings: IMultiSelectTexts = {
    defaultTitle: 'Select...'
  };
  timeMultiselectOptions: IMultiSelectOption[] = [
    { id: 15, name: '15 minutes' },
    { id: 30, name: '30 minutes' },
    { id: 45, name: '45 minutes' },

  ];
  timeMultiselectOptions2: IMultiSelectOption[] = [
    { id: 15, name: '15 minutes' },
    { id: 30, name: '30 minutes' },
  ];
  professions = Professions;
  rankingOpts = EvalRankingOptions;
  patientAvg = PatientAverage;
  yesNoOpts = YesNoOptions;
  certTypes = CertificateTypes;
  certTemp;
  subs: Subscription[] = [];
  evalArray = [];

  topicTitle;
  evalone = false;
  evaltwo = false;
  evalthree = false;
  evalSubmit = false;
  private readonly pdfFonts: any;
  pdfMake: any;
  downloadCertificateSubject: Subject<string> = new Subject<string>();
  user$ = this.loginContainer.currentUser;
  user2: User;
  activity$ = this.activityContainer.activityData;

  certificateTypeId = 3;
  moduleId: string;
  form: FormGroup;
  submitted = false;
  message: SimpleMessage;
  eval: ActivityTest;
  convertedCredit: number;
  formModel: DynamicFormModel;
  formGroup: FormGroup;

  evalResponse: ActivityTestResponse = {};
  currentModuleIndex: number;

  evalResponseKeyData;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private topicService: TopicService,
    private certService: CertificateService,
    public trackbyService: TrackByService,
    private loginContainer: LoginContainer,
    private activityContainer: ActivityContainer,
    private activityService: ActivityService,
    private formService: DynamicFormService,
    private formConverter: FormConverterContainer,
    private logger: LoggerService,
    public fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private googleEvent: GoogleEventsService,
    private storageContainer: StorageContainer,
  ) {
    this.topicTitle = this.route.snapshot.params.username;
    this.pdfMake = require('pdfmake/build/pdfmake.js');
    this.pdfFonts = require('pdfmake/build/vfs_fonts.js');
    this.pdfMake.vfs = this.pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    this.buildForm();
    this.subs.push(
      ...[
        this.subscribeToConditionalFieldValidation(
          this.evalFormGroup.get('commitment.commit'),
          this.commitmentDescriptionCtrl,
          'Yes'
        ),
        this.subscribeToConditionalFieldValidation(
          this.evalFormGroup.get('biasFree.rank'),
          this.biasExplanationCtrl,
          'No'
        ),

        this.subscribeToCurrentTopic(),
      ]
    );

    this.ValidateRequireBasedOnTopic();
    if (this.topicId === 12) {
      this.evalone = true;
      this.moduleId = '5F8AABD5-8712-49E9-8F0B-CE90A6BBA12C';
      this.evalResponse.ActivityTestId = '7b60a02f-6e50-4d50-92f3-b56c271d6804';
      this.evalResponseKeyData = EvalModule1;
    } else if (this.topicId === 13) {
      this.evaltwo = true;
      this.moduleId = '5F8AABD5-8712-49E9-8F0B-CE90A6BBA44A';
      this.evalResponse.ActivityTestId = '6DF2C7B0-CCEE-4539-849A-746C0775F0B2';
      this.evalResponseKeyData = EvalModule2;
    } else if (this.topicId === 14) {
      this.evalthree = true;
      this.moduleId = '5F8AABD5-8712-49E9-8F0B-CE90A6BBA55D';
      this.evalResponse.ActivityTestId = '9252E6C7-FC19-42EC-9B01-053B0B260E8B';
      this.evalResponseKeyData = EvalModule3;
    }
    this.subscribeToUser();
    this.getEvaluation(this.moduleId);
    this.evalResponse.ModuleId = this.moduleId;
   
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  subscribeToCurrentTopic() {
    return this.route.params.subscribe(params => {
      this.topicId = parseInt(params['topicId'], 10);
    });
  }


  subscribeToUser() {
    return this.user$.subscribe(user => {
      this.user2 = user;
      // this.evalResponse.UserId = user?.ActivityStatus.UserActivityId;
      this.evalResponse.UserId = this.user2.UserId;
    });
  }

  validateUserTopic() {
    this.userService.topicSource.take(1).subscribe(id => {
      this.userCurrentTopicId = id;
    });
    if (this.userCurrentTopicId && this.userCurrentTopicId !== this.topicId) {
      this.userService.setUserTopic(this.userCurrentTopicId);
      this.router.navigate(['/evaluation', this.userCurrentTopicId]);
    }
  }

  getEvaluation(moduleId: string) {
    this.activityService.getEvaluationByModuleId(moduleId)
      .pipe(take(1))
      .subscribe(data => {
        this.eval = data;
        this.evalResponse.ActivityTestId = this.eval.ActivityTestId;
        if (this.eval) {
          this.formModel = this.formConverter.getFormModel(this.eval);

          if (this.formModel) {
            this.formGroup = this.formService.createFormGroup(this.formModel);
          }

        }
      });
  }


  buildForm() {
    this.evalFormGroup = this.fb.group({
      profession: this.fb.group({
        proff: this.fb.control('', [Validators.required])
      }),
      specialty: ['', Validators.required],
      avgPatient: this.fb.group({
        patientCount: this.fb.control('', [Validators.required])
      }),
      evidenceBased: this.fb.group({
        rank: this.fb.control('', [Validators.required])
      }),
      biasFree: this.fb.group({
        rank: this.fb.control('', [Validators.required])
      }),
      biasExplanation: [''],
      describeGLP: this.fb.group({
        rank: this.fb.control('')
      }),
      evaluateGLP: this.fb.group({
        rank: this.fb.control('')
      }),
      discussGLP: this.fb.group({
        rank: this.fb.control('')
      }),
      relevant: this.fb.group({
        rank: this.fb.control('', [Validators.required])
      }),
      educational: this.fb.group({
        rank: this.fb.control('', [Validators.required])
      }),
      knowledge: this.fb.group({
        rank: this.fb.control('', [Validators.required])
      }),
      confidence: this.fb.group({
        rank: this.fb.control('', [Validators.required])
      }),
      unansweredQuestion: ['', Validators.required],
      infoLearned: ['', Validators.required],
      relatedTopics: ['', Validators.required],
      commitment: this.fb.group({
        commit: this.fb.control('', [Validators.required])
      }),
      commitmentDescription: [''],
      time: ['', Validators.required],
      certificateType: this.fb.group({
        type: this.fb.control('', [Validators.required])
      }),
      name: ['', Validators.required]
    });


    // easier template access
    this.professionCtrl = this.evalFormGroup.controls['profession'];
    this.specialtyCtrl = this.evalFormGroup.controls['specialty'];
    this.avgPatientCtrl = this.evalFormGroup.controls['avgPatient'];
    this.evidenceBasedCtrl = this.evalFormGroup.controls['evidenceBased'];
    this.biasFreeCtrl = this.evalFormGroup.controls['biasFree'];
    this.biasExplanationCtrl = this.evalFormGroup.controls['biasExplanation'];
    this.describeGLPCtrl = this.evalFormGroup.controls['describeGLP'];
    this.evaluateGLPCtrl = this.evalFormGroup.controls['evaluateGLP'];
    //this.discussGLPCtrl = this.evalFormGroup.controls['discussGLP'];
    this.relevantCtrl = this.evalFormGroup.controls['relevant'];
    this.educationalCtrl = this.evalFormGroup.controls['educational'];
    this.knowledgeCtrl = this.evalFormGroup.controls['knowledge'];
    this.confidenceCtrl = this.evalFormGroup.controls['confidence'];
    this.unansweredQuestionCtrl = this.evalFormGroup.controls['unansweredQuestion'];
    this.infoLearnedCtrl = this.evalFormGroup.controls['infoLearned'];
    this.relatedTopicsCtrl = this.evalFormGroup.controls['relatedTopics'];
    this.commitmentCtrl = this.evalFormGroup.controls['commitment'];
    this.commitmentDescriptionCtrl = this.evalFormGroup.controls['commitmentDescription'];
    this.timeCtrl = this.evalFormGroup.controls['time'];
    this.certificateTypeCtrl = this.evalFormGroup.controls['certificateType'];
    this.nameCtrl = this.evalFormGroup.controls['name'];

    
  }

  addCertificate() {
    this.credits = this.topicService.convertTopicTimeToCredits(0, this.timeCtrl.value);
    let certificateData: Certificate = {
      UserId: this.evalResponse.UserId,
      Name: this.nameCtrl.value,
      ModuleId: this.evalResponse.ModuleId,
      ActivityId: environment.activityApi.activityId,
      CertifyDate: new Date(),
      TypeId: 1,
      Credits: Number(this.credits),
    };//,
    //     currentModel = this.user.ActivityStatus.Modules.find(m => m.ModuleId.toLowerCase() == this.moduleId.toLowerCase());
    // if(currentModel && currentModel.Certificate) {
    //     certificateData.CertificateId = currentModel.Certificate.CertificateId;
    // }
    this.activityService.addOrUpdateModuleCertificate(certificateData)
      .pipe(take(1))
      .subscribe(cert => {
        // this.user.ActivityStatus.Modules[ this.currentModuleIndex ].Certificate = cert;

        //  this.loginContainer.updateUser(this.user);
        // this.submitted = true;
        //  this.router.navigate(['/']);

      }, error => {

        this.logger.logError(error);
      });

    // this.loading = false;
    //  this.router.navigate(['/']);

  }


  onSubmit(formData) {
    if (!this.evalFormGroup.valid) {
      window.scroll(0, 0);
      return;
    }
    // console.log(formData)
    this.evalResponse.Responses = this.getFormattedEvalFormData(formData);
    this.evalResponse.UserId = this.user2.UserId;
    this.activityService.addActivityResponses(this.evalResponse)
      .pipe(take(1))
      .subscribe(response => {
        this.addCertificate();
      },
        error => {
          console.log(error);
          this.loading = false;
          this.logger.logError(error);

          this.message = {
            MessageClass: 'danger',
            IconClass: 'pi-exclamation-circle',
            Summary: 'Sorry, there was an error completing your evaluation. Please try again.'
          };
        });
        this.evalSubmit = true;
  }

  isRequiredField(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.isRequiredField(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  ValidateRequireBasedOnTopic() {
    switch (this.topicId) {
      case 12: {
        //this.evalFormGroup.get('describeGLP.rank').setValidators([Validators.required]);
        break;
      }
      case 13:
      case 14: {
        //this.evalFormGroup.get('evaluateGLP.rank').setValidators([Validators.required]);
        break;
      }
      case 15: {
        //this.evalFormGroup.get('discussGLP.rank').setValidators([Validators.required]);
        break;
      }
    }
  }

  subscribeToConditionalFieldValidation(
    control: AbstractControl,
    dependentControl: AbstractControl,
    valueToRequire: any
  ) {
    return control.valueChanges.subscribe((answer: any) => {
      if (answer === valueToRequire) {
        dependentControl.setValidators([Validators.required]);
      } else {
        dependentControl.setValidators(null);
      }
      dependentControl.updateValueAndValidity();
      return dependentControl;
    });
  }

  displayTitle() {
    return this.topicService.getTopicById(this.topicId).title;
  }
  openPDF(name, cert) {
    this.credits = this.topicService.convertTopicTimeToCredits(0, this.timeCtrl.value);
    cert = this.evalFormGroup.get('certificateType.type').value;
    let innerContent = {};
    let check = this.topicId;

    let date;
    switch (cert) {
      case 'CE':
      case 'CDE':
      case 'RN':
      case 'NP':
        date = new Date().toString();
        innerContent = {
          pageSize: this.certService.pageSize,
          pageMargins: this.certService.pageMargin,
          background: [
            {
              image: this.certService.bgUri.background,
              width: this.certService.bgUriWidth
            }
          ],
          content: [
            {
              text: '',
              style: 'header',
              margin: [0, 30, 0, 0]
            },
            {
              text: '',
              style: 'paragraph',
              margin: [0, 10, 0, 0]
            },
            {
              text: name,
              style: 'paragraph',
              fontSize: 18,
              bold: true,
              margin: [0, 28, 50, 28]
            },
            {
              text: this.displayTitle(),
              style: 'paragraph',
              margin: [0, 2, 15, 0],
              bold: true,
              fontSize: 16
            },
            {
              text: 'at pcosactivity.imne.com',
              style: 'paragraph',
              margin: [0, 1, 12, 0]
            },
            {
              text: date,
              style: 'paragraph',
              margin: [0, 2, 5, 0]
            },


            {
              text: 'and is awarded',
              style: 'paragraph',
              fontSize: 12,
              margin: [0, 2, 60, 0],
              italics: true
            },
            {
              text: this.credits + ' Contact Hour(s)',
              style: 'paragraph',
              fontSize: 11,
              margin: [0, 2, 60, 0],
              italics: true
            },
            // {
            //   text: 'IMNE has determined ' + this.credits + ' hours of this program will satisfy ANCC’S',
            //   style: 'paragraph',
            //   fontSize: 10,
            //   margin: [0, 1, 50, 0]
            // },
            // {
            //   text: 'pharmacotherapeutics contact hour requirement for ANCC certified Clinical Nurse',
            //   style: 'paragraph',
            //   fontSize: 10,
            //   margin: [0, 1, 50, 0]
            // },
            // {
            //   text: 'Specialists and Nurse Practitioners.',
            //   style: 'paragraph',
            //   fontSize: 10,
            //   margin: [0, 1, 50, 0]
            // },
          ],
          styles: {
            header: {
              fontSize: 22,
              alignment: 'center',
              color: '#222'
            },
            paragraph: {
              fontSize: 13,
              alignment: 'center',
              color: '#222'
            }
          }
        };

        break;
      case 'Other':
      case 'Completion':
        date = new Date().toString();
        innerContent = {
          pageSize: this.certService.pageSize,
          pageMargins: this.certService.pageMargin,
          background: [
            {
              image: this.certService.bgUri.background,
              width: this.certService.bgUriWidth
            }
          ],
          content: [
            {
              text: '',
              style: 'header',
              margin: [0, 30, 0, 0]
            },
            {
              text: '',
              style: 'paragraph',
              margin: [0, 10, 0, 0]
            },
            {
              text: name,
              style: 'paragraph',
              fontSize: 18,
              bold: true,
              margin: [0, 28, 50, 28]
            },
            {
              text: this.displayTitle(),
              style: 'paragraph',
              margin: [0, 2, 15, 0],
              bold: true,
              fontSize: 16
            },
            {
              text: 'at pcosactivity.imne.com',
              style: 'paragraph',
              margin: [0, 2, 12, 0]
            },
            {
              text: date,
              style: 'paragraph',
              margin: [0, 2, 5, 0]
            },

            {
              text: 'This educational activity is accredited for',
              style: 'paragraph',
              fontSize: 10,
              margin: [0, 10, 60, 0],
              italics: true
            },

            {
              text: '2.0  AMA PRA Category 1 Credits™',
              style: 'paragraph',
              fontSize: 10,
              margin: [0, 3, 60, 0],
              italics: true
            },


          ],
          styles: {
            header: {
              fontSize: 22,
              alignment: 'center',
              color: '#222'
            },
            paragraph: {
              fontSize: 13,
              alignment: 'center',
              color: '#222'
            }
          }
        };


        break;
      case 'CME':
      case 'PA':
      case 'MD/DO':
      default:
        date = new Date().toString();
        innerContent = {
          pageSize: this.certService.pageSize,
          pageMargins: this.certService.pageMargin,
          background: [
            {
              image: this.certService.bgUri.background,
              width: this.certService.bgUriWidth
            }
          ],
          content: [
            {
              text: '',
              style: 'header',
              margin: [0, 30, 0, 0]
            },
            {
              text: '',
              style: 'paragraph',
              margin: [0, 10, 0, 0]
            },
            {
              text: name,
              style: 'paragraph',
              fontSize: 18,
              bold: true,
              margin: [0, 28, 50, 28]
            },
            {
              text: this.displayTitle(),
              style: 'paragraph',
              margin: [0, 2, 15, 0],
              bold: true,
              fontSize: 16
            },
            {
              text: 'at pcosactivity.imne.com',
              style: 'paragraph',
              margin: [0, 2, 12, 0]
            },
            {
              text: date,
              style: 'paragraph',
              margin: [0, 2, 5, 0]
            },


            {
              text: 'and is awarded',
              style: 'paragraph',
              fontSize: 12,
              margin: [0, 10, 60, 0],
              italics: true
            },
            {
              text: this.credits + '  AMA PRA Category 1 Credits™',
              style: 'paragraph',
              fontSize: 11,
              margin: [0, 3, 60, 0],
              italics: true
            },


          ],
          styles: {
            header: {
              fontSize: 22,
              alignment: 'center',
              color: '#222'
            },
            paragraph: {
              fontSize: 13,
              alignment: 'center',
              color: '#222'
            }
          }
        };

    }

    this.pdfMake.createPdf(innerContent).download();
    this.downloadPdf();
  }

  downloadPdf() {
    // this.googleEvent.trackGaEvent(`download`, `evaluation_certificate`, `Download PDF | topic: ${
    //   this.topicService.getTopicById(this.topicId).title
    //   }`);
    // this.googleEvent.trackGaConversion(environment.googleTags.adsId);

    // this.googleEvent.googleAnalyticsTrackEvent(
    //   `download`,
    //   `evaluation_certificate`,
    //   `Download PDF | topic: ${
    //   this.topicService.getTopicById(this.topicId).title
    //   }`
    // );

    // this.googleEvent.adWordsConversion('EMOYCMPTibMBEP2M2e4D');
    this.router.navigateByUrl('/mymods');

  }

  getResponseModel(formData): QuestionResponse[] {
    let responseObj = this.flattenFormValues(formData),
        questionResponses: QuestionResponse[] = [];

    for (const [ key, value ] of Object.entries(responseObj)) {
        let response: QuestionResponse = {
            QuestionId: key
        };

        if (typeof value === 'string' &&  this.isGuid(value.toString())) {

            response.AnswerId = value.toString();
        } else {
            if(value!=null){
                response.ResponseText = value.toString();
            }else{
                response.ResponseText = '';
            }
        }

        questionResponses.push(response);
    }

    return questionResponses;
}



  // Triage for fix to submit form with data
getFormattedEvalFormData(formData) {
  let responseBuilder = [];
  for(const [key, value] of Object.entries(formData)) {
    let ctrlAlias = key,
    ctrlValue = value;
    if(typeof value === 'object') { 
        ctrlValue = Object.values(value)[0];
    }

    if(key === "time") {
        ctrlValue = ctrlValue.toString() + " minutes";
    }

    let question = this.evalResponseKeyData.find(q => q.Alias === ctrlAlias);
    
    if(question && ctrlValue) {
        let answers = question.Answers;

    let chosenAnswer = null;
    if(answers.length == 1 || question.Type === 'textarea') {
        chosenAnswer = answers[0];
    } else {
        chosenAnswer = answers.find(a => a.Text === ctrlValue);
    }
   
    if(chosenAnswer) {
        responseBuilder.push({
            QuestionId: question.QuestionId,
            AnswerId: chosenAnswer.AnswerId,
            IsCorrectAnswer: chosenAnswer.IsCorrectAnswer,
            ResponseText: ctrlValue,
        })
    }

    }
    
}
  return responseBuilder;
}


private flattenFormValues(obj): any {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[ key ] === 'object' && obj[ key ] !== null) {
            Object.assign(flattened, this.flattenFormValues(obj[ key ]));
        } else {
            flattened[ key ] = obj[ key ];
        }
    });

    return flattened;
}


private isGuid(value: string) {
    let regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(value);
}
}
