
 <div class="logindiv row px-3 px-md-0">
        <div class="col-md-10 offset-md-5 pb-3 px-0 mx-auto login bg-white rounded " >

        <!-- <ul ngbNav  #shipmentSummaryPageTabSet="ngbNav" justify="center" class="" [activeId]="'login'" >
            <li [ngbNavItem]="'login'"   title="">
                  <ng-template ngbNavTitle>
                    Login
                  </ng-template>

            <ng-template ngbNavContent>
                <app-register></app-register> -->
            <form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="container p-0">
                <div class="row">
                    <div class="col-12 pb-3">
                            <label for="email" class="form-label">Email</label>
                            <input class="form-control" type="email" formControlName="email">
                    </div>
                    <div class="col-12">
                            <label for="passwordHash" class="form-label">Password</label>
                            <input class="form-control" type="password" formControlName="passwordHash">
                    </div>
                    <div class="col-12 pt-3" *ngIf="message && message.length > 0">
                        {{ message }}
                    </div>
                    <div class="col-12 pt-3">
                    <div class="d-flex align-items-center">
                            <button class="btn btn-secondary text-white me-2" type="submit" [disabled]="submitted || !loginForm.valid">Submit</button>
                        <button class="btn btn-secondary text-white me-3" type="button" (click)="onCancelForm()">Cancel</button>
                        <p-progressSpinner *ngIf="loading" styleClass="default-spinner"></p-progressSpinner>
                    </div>
                    <div class="d-flex align-items-center justify-content-between form-footer-links">
                            <p class="mt-3"><span class="d-inline-block me-2 text-primary">Not registered?</span> <a class="text-secondary d-inline-flex align-items-center text-decoration-none link-arrow link-arrow-orange" (click)="onGoToRegister()">Sign up</a></p>
                    </div>
                    </div>
                </div>
            </form>
            <!-- </ng-template>
            </li>
                <li [ngbNavItem]="'Register'"   title="">
                    <ng-template ngbNavTitle>
                    Sign Up</ng-template>

                    <ng-template ngbNavContent>
                        <app-register></app-register>
                    </ng-template>
                </li>
            </ul> -->
</div>
</div>
<!-- <div [ngbNavOutlet]="shipmentSummaryPageTabSet" id="navContent" style="background-color:black;"></div> -->

