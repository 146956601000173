<div class="row pretest-quiz">
  <div class="col-md-10 mx-auto">
    <h3>Pre Activity Assessment Questions</h3>

    <p class="text-justify pb-4 border-bottom" *ngIf="topicData.pretestOverview" >Please complete the following assessment questions prior to the activity.</p>

    <div class="row">
      <div class="col-md-11 mx-auto">

        <div *ngFor="let question of questions" class="test-form mb-5">
          <p class="lead" [innerHTML]="question.label"></p>
          <span class="custom-control custom-checkbox mb-3" *ngFor="let option of question.options; let i = index">
            <input id="pretest-{{question.id}}-{{i}}" class="d-inline custom-control-input" type="checkbox" (change)="onChange(question, option)"
              [(ngModel)]="option.selected" [checked]="isChecked">
            <label for="pretest-{{question.id}}-{{i}}" class="d-inline pl-md-2 custom-control-label">&nbsp;&nbsp;{{i + 1}}. &nbsp;{{option.label}}</label>
          <br/></span>

        </div>

        <button class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" type="submit" (click)="onSubmit()" [disabled]="answerArray.length < questions.length"
          *ngIf="!isSubmitted">
          Submit Answers
        </button>

        <button class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" (click)="goToCaseStudy()" *ngIf="isSubmitted">Continue to the Video Lesson</button>

        <div *ngIf="submissionMessage" class="alert alert-success mt-4" role="alert">
          {{ submissionMessage }}
        </div>

      </div>
    </div>

  </div>
</div>
<br/>
<br/>
