<div class="mscontainer mb-5" >

<!-- <button *ngIf="!isLogged" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" [routerLink]="['/login']">Login to view content</button> -->
<button *ngFor="let topic of topics" (click)="goToTopic(topic)" class="card character card-btn text-dark flex-md-row mb-4">
  <img [src]="topic.icon" [alt]="topic.caseTitle" class="character-icons">
  <div class="card-body character-body d-flex flex-column">
    <strong class="text-uppercase character-body__status" [ngClass]="{'text-muted': topic.status === 'Coming Soon', 'text-primary': topic.status === 'Complete', 'text-success': topic.status === 'Active' , 'text-warn': topic.status === 'Not Started'}">{{ topic.status }}</strong>
    
    <p class="card-text lead mb-auto" [innerHTML]="topic.caseDescription"></p>
  </div>

</button>
</div>
<br/>
<br/>
<br/>