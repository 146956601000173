import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CMEInfo } from '../../data/settings';
import { TopicData } from '../../data/topics';
import { ActivityOverview } from '../../data/settings';
import { ModalLauncherService } from '../../services/modal-launcher/modal-launcher.service';
// import { ActivityOverviewModule } from '../activity-overview/activity-overview.module';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
 
})
export class ActivityComponent implements OnInit {
  activity = ActivityOverview;
  topics = TopicData;
  activityBtnDisabled = true;
  scrollBoxRef: any;
  @ViewChild('scrollBox') scrollBox: ElementRef;
  @Output() public showOverviewEventEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalLauncherService,
    // private activityBox: ActivityOverviewModule,

    ) {
      let isScrolled = localStorage.getItem('scrolled');

      if (isScrolled) {
          this.activityBtnDisabled = false;
      }


    }

  ngOnInit() {}
      ngAfterViewInit() {
        this.scrollBoxRef = this.scrollBox.nativeElement;
    }

    trackScrollBox($event) {
        if (((this.scrollBoxRef.offsetHeight + 5) + this.scrollBoxRef.scrollTop) >= this.scrollBoxRef.scrollHeight) {
            localStorage.setItem('scrolled', '1');
            this.activityBtnDisabled = false;
        }
    }
  onShowInfoModal($event) {
    this.modalService.launchInformationModal(CMEInfo);
  }
}
