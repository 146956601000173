<div class="testover row list-inline"  >

<!-- <button *ngIf="!isLogged" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" [routerLink]="['/login']">Login to view content</button> -->
<ul class="topicboxs">
<li *ngFor="let topic of topics" (click)="goToTopic(topic)" class=" mx-1 col-3  card character card-btn  d-inline list-inline-item">
  <img [src]="topic.icon" [alt]="topic.caseTitle"   >


</li>
</ul>
</div>