import { Injectable } from '@angular/core';
import {
    DynamicFormModel,
    DynamicCheckboxModel,
    DynamicInputModel,
    DynamicRadioGroupModel,
    DynamicTextAreaModel,
    DynamicFormGroupModel,
    DynamicFormLayout,
    DynamicSelectModel,
} from "@ng-dynamic-forms/core";
import { MATCH_HIDDEN, MATCH_REQUIRED } from "@ng-dynamic-forms/core";
import { ActivityTest, ActivityTestResponse, QuestionResponse, Question } from "../../models/activity-test.model";
import { SimpleMessage } from "../../models/base.model";
import {  User, UserModule } from "../../models/user2.model";

@Injectable()
export class FormConverterContainer {



    getFormModel(activityTest: ActivityTest): DynamicFormModel {
        let model: DynamicFormModel = [];

        activityTest.QuestionGroups.forEach((group, idx) => {
            model.push(this.generateFormGroup(group, idx));
        });


        return model;
    }

    generateFormGroup(questions: Question[], idx: number): DynamicFormGroupModel  {
        let groupModel = new DynamicFormGroupModel({
            id: idx.toString(),
            group: [],
        },
        {
            grid: {
                container: 'eval-section px-2 px-sm-4'
            }
        });

        questions.forEach(question => {
            groupModel.group.push(this.generateFormControl(question)); 
        });

        return groupModel;
    
    }

    generateFormControl(question: Question) {
        let control = null;
        switch (question.Type) {
            case 'RadioButton':
                control = new DynamicRadioGroupModel<string>(this.generateGenericControlConfig(question), {
                    element: {
                        label: 'form-label'                    },
                    grid: {
                        container: 'row form-row',
                        label: 'col-12 col-md-5',
                    }
                });
                break;
            case 'TextArea':
                control = new DynamicTextAreaModel(this.generateGenericControlConfig(question), {
                    element: {
                        label: 'form-label',
                        control: 'form-control'
                    },
                    grid: {
                        container: 'row form-row',
                        label: 'col-12 col-md-5',
                    }
                });
                break;
                case 'SelectList':
                control = new DynamicSelectModel<string>(this.generateGenericControlConfig(question), {
                    element: {
                        label: 'form-label'
                    },
                    grid: {
                        container: 'row',
                        label: 'col-12 col-md-5',
                    }
                })
                break;
            // Checkbox, InputText, SelectList, MultiSelectList
            default:
                break;
        }

        return control;
    }

    generateGenericControlConfig(question: Question) {
        let controlConfig = {
            id: question.QuestionId,
            label: question.Text,
            options: [],
            validators: {},
            errorMessages: {},
        };

        question.Answers.forEach(answer => {
            controlConfig.options.push({
                label: answer.Text,
                value: answer.AnswerId
            });
        });

        if (question.IsRequired) {
            controlConfig.validators = {
                required: null
            };
            controlConfig.errorMessages = {
                required: 'Required'
            };
        }

        return controlConfig;
    }

    generateDependentControlConfig(question: Question) {
        let dependence = [
            
        ];
    }

}