<div class="modal-content">
  <div class="modal-header d-flex justify-content-between bg-primary text-white">
    <h4>CME/CE Information</h4>
    <a (click)="closeModal()">
      <i class="fas fa-times-circle"></i>
    </a>
  </div>

  <div class="modal-body">
    <div *ngFor="let section of infoContent">
      <div [innerHTML]="section.content"></div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-4">
        <!-- <a [routerLink]="['/login']" (click)="closeModal()" data-dismiss="modal">
          <img class="img-fluid px-5 px-md-0 mb-5" src="assets/images/buttons/enter-activity-btn.png" alt="Enter Activity">
        </a> -->
      </div>
    </div>
  </div>
</div>
