import { Component, OnDestroy, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Subscription } from 'rxjs';

import { IRationaleModal } from '../../../models/modal.model';
import { TopicService } from '../../../services/topic/topic.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-rationale-modal',
  templateUrl: './rationale-modal.component.html',
  styleUrls: ['./rationale-modal.component.scss'],
})
export class RationaleModalComponent
  extends SimpleModalComponent<IRationaleModal, null>
  implements OnInit, OnDestroy {
  questionId: number;
  testType: string;
  topicId: number;
  rationale: string;

  private subs: Subscription[] = [];

  constructor(
    private topicService: TopicService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      ...[this.subscribeToRationaleData(), this.subscribeToTopicId()]
    );

    this.setQuestionRationale();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe);
   // super.ngOnDestroy();
  }

  subscribeToRationaleData() {
    return this.topicService.currentRationaleTestType$.subscribe(t => {
      this.testType = t;

      this.topicService.currentRationaleQuestionId$.subscribe(q => {
        this.questionId = q;
      });
    });
  }

  subscribeToTopicId() {
    return this.userService.userTopic$.subscribe(id => {
      this.topicId = id;
    });
  }

  setQuestionRationale() {
    this.rationale = this.topicService.getRationaleByTestTypeAndQuestionId(
      this.topicId,
      this.questionId,
      this.testType
    );
  }

closeModal() {
    this.close();
  }
}
