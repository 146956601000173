import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CMEInfo } from '../../data/settings';
import { IUserStatus } from '../../models/user-status.model';
import { AuthService } from '../../services/auth/auth.service';
import { ModalLauncherService } from '../../services/modal-launcher/modal-launcher.service';
import { UserService } from '../../services/user/user.service';
import { LoginContainer } from 'src/app/containers/login.container';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLogged: boolean;
  status: IUserStatus;
  topicId: number;

  private subs: Subscription[] = [];

  constructor(

   
    private userService: UserService,
    private authService: AuthService,
    private modalService: ModalLauncherService,
    private router: Router,
    private loginContainer: LoginContainer,
  ) {}

  ngOnInit() {
    this.isLogged = this.authService.isUserLoggedIn();
    //this.subs.push(...[this.subscribeToUser(), this.subscribeToUserTopic()]);
    this.router.events.subscribe((val) => {
      // see also 
      this.isLogged = this.authService.isUserLoggedIn();
  });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe);
  }
  setLog() {
    this.isLogged =true;
    //this.subs.push(...[this.subscribeToUser(), this.subscribeToUserTopic()]);
  }
  subscribeToUser() {
    // return this.userService.getStoredUserStatus().subscribe(s => {
    //   this.isLogged = s.status ? true : false;
    //   this.status = s.status;
    //   return status;
    // });
  }

  subscribeToUserTopic() {
    return this.userService.getStoredUserStatus().subscribe(s => {
      const currentStatus = this.userService.getCurrentUserTopic(s.status);
      if (currentStatus) {
        this.topicId = currentStatus.TopicID;
      }
      return this.topicId;
    });
  }

  goToTopic() {
    if (this.topicId) {
      this.router.navigate(['/pretest', this.topicId]);
    }
  }

  goToTopic2(num) {
      this.router.navigate(['/pretest', num]);
    
  }

  logout() {
  // this.authService.logoutUser();
  //   this.router.navigate(['/login']);
  this.loginContainer.endUserSession();
  this.isLogged =false;
  }

  onShowInfoModal($event) {
    this.modalService.launchInformationModal(CMEInfo);
  }
}
