import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IUserStatus, IUserTopic } from '../../models/user-status.model';
import { IUser } from '../../models/user.model';
import { ResetUserTopic, SetUserTopic } from '../../state/actions/topic.action';
import {
  ResetUserStatus,
  SetUserStatus,
} from '../../state/actions/user-status.action';
import { State } from '../../state/rootReducer';
// import { ConsoleReporter } from 'jasmine';

@Injectable()
export class UserService {
  List: any;
  activityInfo = environment.activity;
  apiInfo = environment.activityApi;

  statusSource: BehaviorSubject<IUserStatus> = new BehaviorSubject(null);
  userStatus$ = this.statusSource.asObservable();

  topicSource: BehaviorSubject<number> = new BehaviorSubject(null);
  userTopic$ = this.topicSource.asObservable();

  constructor(
    protected store: Store<State>,
    private http: HttpClient,
    private router: Router
  ) {}

  getStoredUserStatus() {
    return this.store.select(s => s.userHistory);
  }

  setCurrentUserStatus(status: IUserStatus) {
    const userTopics = this.normalizeTopicStatus(status.List);
    status.List = userTopics;
    this.statusSource.next(status);
    this.store.dispatch(new SetUserStatus(status));
  }

  resetUserTopicStatus() {
    this.statusSource.next(null);
    this.topicSource.next(null);
    this.store.dispatch(new ResetUserStatus());
    this.store.dispatch(new ResetUserTopic());
  }

  getCurrentUserstatus(user: IUser){
   // let test = this.http.get<IUserStatus>(`${ this.apiInfo.url  }Tests/All/${this.activityInfo.moduleId}/${ user.userAccountID }?Token=${user.Token}`);
    //return test;
  }
    

  getCurrentUserTopic(status: IUserStatus): IUserTopic {
    if (status) {
      const incompleteTopics = this.getUserIncompleteTopics(status.List),
        topicIds = this.activityInfo.topics.sort();
      if (incompleteTopics.length) {
        this.setUserTopicIdByStatus(incompleteTopics[0]);
        return incompleteTopics[0];
      } else {
        const topics = this.formatUserTopics(status.List);
        this.setUserTopicIdByStatus(topics[0]);
        return topics[0];
      }
    }else{
      //console.log(status.List);
      // const incompleteTopics = this.getUserIncompleteTopics(status.List),
      //   topicIds = this.activityInfo.topics.sort();
      // this.setUserTopicIdByStatus(incompleteTopics[0]);
      return null;
    }
  }

  getUserTopicStatusByTopicId(
    status: IUserStatus,
    topicId: number
  ): IUserTopic {
    return status.List.find((item: IUserTopic) => item.TopicID === topicId);
  }

  updateUserTopicStatusByTopicId(
    statusList: IUserStatus,
    topicId: number,
    status: string
  ) {
    const currentTopic = this.getUserTopicStatusByTopicId(statusList, topicId);
    const topicIndex = statusList.List.indexOf(currentTopic);
    currentTopic.Status = status;

    statusList.List[topicIndex] = currentTopic;

    this.setCurrentUserStatus(statusList);

    return statusList;
  }

  getUserTopicByStatus(topics: IUserTopic[], status: string): IUserTopic[] {
    const userTopics = this.formatUserTopics(topics);
    return userTopics.filter(topic => {
      const topicStatus = topic.Status.toLowerCase().trim();
      return topic.Status.indexOf(topicStatus) === 1;
    });
  }

  getUserIncompleteTopics(topics: IUserTopic[]): IUserTopic[] {
    const userTopics = this.formatUserTopics(topics);
    return userTopics.filter(topic => {
      return topic.Status.indexOf('completed') === -1;
    });
  }

  setUserTopicIdByStatus(topic: IUserTopic) {
    this.setUserTopic(topic.TopicID);
  }

  setUserTopic(id: number) {
    this.topicSource.next(id);
    this.store.dispatch(new SetUserTopic(id));
  }

  private normalizeTopicStatus(topics: IUserTopic[]): IUserTopic[] {
    return topics.map(topic => {
      let userStatus = topic.Status.toLowerCase().trim();
      switch (userStatus) {
        case 'completed':
        case 'complete':
          userStatus = 'completed';
          break;

        case 'incompletd':
          userStatus = 'incomplete';
      }
      return {
        TopicID: topic.TopicID,
        Status: userStatus,
        ModuleId: topic.ModuleId,
        ModuleName: topic.ModuleName,
        TopicTitle: topic.TopicTitle,
        Enabled: topic.Enabled,
        SortOrder: topic.SortOrder,
        UserAccountId: topic.UserAccountId,
        LastModifiedDate: topic.LastModifiedDate,
      };
    });
  }

  private formatUserTopics(topics: IUserTopic[]): IUserTopic[] {
    const formatArray = this.normalizeTopicStatus(topics);
    return formatArray.sort((a, b) => {
      return a.TopicID - b.TopicID;
    });
  }
}
