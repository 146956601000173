<div class="row posttest-quiz">
  <div class="col-md-11 mx-auto">
    <h3>Post Activity Assessment Questions</h3>
    <p class="text-justify pb-4 border-bottom" *ngIf="topicData.posttestOverview" >Please complete the following assessment questions prior to the activity</p>

    <div class="row">
      <div class="col-md-10 mx-auto">
        <div *ngFor="let question of questions" class="test-form mb-5">

          <div class="ribbon-background mt-3 mb-5">
            <p class="lead" [innerHTML]="question.label"></p>
          </div>

          <div class="question-wrap" [ngClass]="{ 'question-incorrect': !question.correct && isSubmitted && !question.opinion }">
            <span class="custom-control custom-checkbox mb-3" *ngFor="let option of question.options; let i = index">
              <input id="answerBox-{{question.id}}-{{i}}" class="d-inline custom-control-input" type="checkbox" (change)="onChange(question, option)"
                [(ngModel)]="option.selected" [checked]="isChecked">
              <label for="answerBox-{{question.id}}-{{i}}" class="d-inline pl-md-2 custom-control-label">&nbsp;&nbsp;{{i + 1}}. &nbsp;{{option.label}}</label>
            <br/></span>
          </div>

          <button *ngIf="question.rationale && isSubmitted" class="btn btn-outline-light my-4" (click)="onViewRationale(question.id)">View Rationale </button>


        </div>

        <button *ngIf="!correctButton || correctA" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" (click)="checkAnswers()"
          [disabled]="answerArray.length < questions.length ">
          Submit Answers
        </button>

        <button *ngIf="!correctA && correctButton" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" (click)="onSubmit()"
          [disabled]="answerArray.length < questions.length ">
          Go to the Evaluation
        </button>

        <div *ngIf="correctA && !correctButton" class="alert alert-danger mt-4" role="alert">


          You must get 1 answer correct before submitting post-activity assessment.
        </div>
        <div *ngIf="!correctA && correctButton" class="alert alert-success mt-4" role="alert">
          <span *ngIf="submissionMessage">{{ submissionMessage }}</span>
          <span *ngIf="!submissionMessage">Congratulations! You have passed the post-activity assessment.</span>

        </div>

      </div>
    </div>

  </div>
</div>
<br/>
<br/>