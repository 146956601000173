import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  USER_TOPIC_SET: type<'USER_TOPIC_SET'>('USER_TOPIC_SET'),
  USER_TOPIC_RESET: type<'USER_TOPIC_RESET'>('USER_TOPIC_RESET'),
}

export class SetUserTopic implements Action {
  type = ActionTypes.USER_TOPIC_SET;
  payload: number;

  constructor(userTopic: number) {
    this.payload = userTopic;
  }
}

  export class ResetUserTopic implements Action {
  type = ActionTypes.USER_TOPIC_RESET;
  payload: any;

  constructor() {}
  }

export type Actions =
  SetUserTopic
  | ResetUserTopic;
