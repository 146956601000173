<footer class="global-footer text-light">
  <div class="container2">
    <div class="row align-items-end">
      <div class="col-md-7">
        <img class="img-fluid mb-2 w-50" src="assets/images/imne-logo02.png" alt="IMNE Institute for Medical and Nursing Education">
        <p>
          Sponsored by the Institute for Medical and Nursing Education,&nbsp;Inc.
        </p>
      </div>
      <div class="col">
        <p class="text-support">
          Supported by an educational grant from Novo&nbsp;Nordisk&nbsp;Inc.
        </p>
      </div>
    </div>
  </div>
</footer>