
<div class="activitymain row w-100">
    <img src="../../../assets/images/icons/icon1.png" class="act-icon">
  <div class="col-12 col-md-12">
    <div #scrollBox  (scroll)="trackScrollBox($event)"   class="overview-scroll-box scroll-bar pe-2">
        <h1 [innerHTML]="activity.title"></h1>
        <div [innerHTML]="activity.content"></div>
        <p>
        <img class="img-fluid px-5 px-md-0" width="1000" height="58" src="assets/images/scroll/HeaderBar1.png" > 
    </p>
    
    <p class="app_top">
      <img class="img-fluid px-5 px-md-0" width="96" height="110" src="assets/images/scroll/head1.png" > <strong>Donna H Ryan, MD, FTOS—Program Chair</strong>
        <br/>
        Professor Emerita<br/>

        Pennington Biomedical Research Center<br/>

        Baton Rouge, Louisiana
    </p><br/><br/>
    <p class="app_top">
      <img class="float-left img-fluid px-5 px-md-0" width="96" height="110" src="assets/images/scroll/head3.png" > 
 
        <strong>Andrea Dunaif, MD</strong><br/>

        Lillian and Henry M. Stratton Professor of Molecular Medicine<br/>

        System Chief, Hilda and J. Lester Gabrilove Division of Endocrinology,
        Diabetes and Bone Disease<br/>

        Icahn School of Medicine and Mount Sinai Health System<br/>
  
        New York, New York
    </p><br/>
    <p class="app_top">
      <img class="float-left img-fluid px-5 px-md-0" width="96" height="110" src="assets/images/scroll/head2.png" > 

        <strong>Amy Schutt, MD, MSCI, FACOG</strong><br/>
 
        Associate Fellowship Program Director<br/>
  
        Division of Reproductive Endocrinology and Infertility<br/>
        Department of Obstetrics and Gynecology<br/>
        Baylor College of Medicine<br/>
        Houston, Texas<br/>
    </p>
    <p>
      <img class="img-fluid px-5 px-md-0 mb-2" width="1000" height="58" src="assets/images/scroll/HeaderBar2.png" > 
      <br/><br/>
        This activity is intended for physicians, physician assistants, nurse
        practitioners, nurses, and other healthcare providers (HCPs) who may
        diagnose, treat, or manage women with PCOS and other health disorders
        related to unhealthy weight or obesity.
    </p>
    <p>
      <img class="img-fluid px-5 px-md-0 mb-2" width="1000" height="58" src="assets/images/scroll/HeaderBar3.png" > 
        <br/><br/>
        Upon completion of this activity, participants will be able to:
    </p>
    <ul>
        <li>
            <p>
                Describe the relationship between excess weight/obesity and women’s
                health (ie, PCOS, infertility, assisted reproduction techniques,
                urinary incontinence)
            </p>
        </li>
        <li>
            <p>
                Implement behavioral intervention approaches for discussing weight
                loss goals and treatment approaches
            </p>
        </li>
        <li>
            <p>
                Discuss current and emerging incretin-based agents and bariatric
                surgery for the management of obesity and related disorders in
                women with PCOS
            </p>
        </li>
    </ul>
    <p>
      <img class="img-fluid px-5 px-md-0 mb-2" width="1000" height="58"  src="assets/images/scroll/HeaderBar4.png" > <br/>
    </p>
    <p>
        <em><strong>Physicians </strong></em>
    </p>
    <p>
        The Institute for Medical and Nursing Education, Inc. (IMNE), is accredited
        by the Accreditation Council for Continuing Medical Education to provide
        continuing medical education for physicians.
    </p>
    <p>
    IMNE designates this enduring activity for a maximum of 2.0    <em>AMA PRA Category 1 Credits</em>™. Physicians should claim only the
        credit commensurate with the extent of their participation in the activity.
    </p>
    <p>
        <em><strong>Nurses/Nurse Practitioners</strong></em>
    </p>
    <p>
        IMNE is accredited as a provider of nursing continuing professional
        development by the American Nurses Credentialing Center’s (ANCC’s)
        Commission on Accreditation.
    </p>
    <p>
        IMNE designates this educational activity for 2.0 contact hours of
        continuing education credit. Accreditation by the ANCC’s Commission on
        Accreditation refers to recognition of educational activities and does not
        imply approval or endorsement of any product.
    </p>
    <p class="upp">
        <strong>Disclosures</strong>
    </p>
    <p>
        It is the policy of IMNE to ensure fair balance, independence, objectivity, and scientific rigor in all programming. All individuals involved in planning (eg, faculty, CME/CE provider staff, and educational partner staff) are expected to disclose any significant financial relationships with commercial interests 
        <em><strong>over the past 24 months</strong></em>
        . It is also required that faculty identify and reference off-label product or investigational uses of pharmaceutical agents and medical devices.

    </p>
    <p>
        <strong>Mitigation of Relevant Financial Interests</strong>
    </p>
    <p>
        IMNE utilizes a peer review process to mitigate potential and actual conflicts of interest disclosed by all persons in a position to influence the planning process or content development of this educational activity. All peer reviewers are without relevant financial relationships.  A copy of the mitigation worksheet is available by request from <a href="mailto:steve.weinman@imne.com">steve.weinman@imne.com</a>
    </p>
    <p>
        The following disclosures have been made:
    </p>
    <p>
        <u>Faculty</u>
    </p>
    <p>
        <em><strong>Donna H Ryan, MD, FTOS—Program Chair</strong></em>
    </p>
    <p>
        Consultant: Alyvant, Amgen, Bausch Health, Boehringer Ingelheim, Epitomee
        Medical, Gila Therapeutics, Janssen, Novo Nordisk Inc., Phenomix, Real
        Appeal, ReDesign Health, Sanofi, Scientific Intake.
    </p>
    <p>
        Stock: Epitomee Medical, Gila Therapeutics, Phenomix, ReDesign Health,
        Scientific Intake, Xeno Biosciences.
    </p>
    <p>
        Steering Committee: Novo Nordisk Inc.
    </p>
    <p>
        Dr Ryan discloses that she may discuss investigational and/or off-label
        uses of some pharmaceutical agents.
    </p>
    <p>
        <em><strong>Andrea Dunaif, MD</strong></em>
    </p>
    <p>
        Consultant: Fractyl Laboratories, Inc., Equator Therapeutics.
    </p>
    <p>
        Scientific Advisory Board: Florida Hospital.
    </p>
    <p>
        Co-Editor: Endocrine Today, Healio News.
    </p>
    <p>
        Dr Dunaif discloses that she does not intend to discuss investigational
        and/or off-label uses of pharmaceutical agents.
    </p>
    <p>
        <em><strong>Amy Schutt, MD, MSCI, FACOG</strong></em>
    </p>
    <p>
        Dr Schutt has no financial relationships to disclose.
    </p>
    <p>
        Dr Schutt discloses that she does not intend to discuss investigational
        and/or off-label uses of pharmaceutical agents.
    </p>
    <p>
        <u>IMNE Staff</u>
    </p>
    <p>
        <a name="_Hlk93923429"></a>
        <em><strong>Amy Groves, BS (With Honors)</strong></em>
    </p>
    <p>
        Business Lead
    </p>
    <p>
        Ms Groves has nothing to disclose.
    </p>
    <p>
        <em><strong>Steve Weinman, MSc, RN</strong></em>
    </p>
    <p>
        Director of Accreditation
    </p>
    <p>
        <a name="_Hlk93644165"></a>
        ANCC Accredited Provider Program Director
        <br/>
        Mr Weinman has nothing to disclose.
    </p>
    <p>
        <em><strong>Michelle Fisher, BS</strong></em>
        <br/>
        Account Coordinator
    </p>
    <p>
        Ms Fisher has nothing to disclose.
    </p>
    <p>
        <em><strong>Rebecca De Saulles, BSc</strong></em>
    </p>
    <p>
        Medical Writer
    </p>
    <p>
        Ms De Saulles has nothing to disclose.
    </p>
    <p>
        <u>CME/CE Peer Reviewers</u>
    </p>
    <p>
        <strong>Martin Quan, MD</strong>
    </p>
    <p>
        Dr Quan has nothing to disclose.
    </p>
    <p>
        <strong>Catherine Lombardi, MSN, RN</strong>
    </p>
    <p>
        Ms Lombardi has nothing to disclose.
    </p>
   
    <!-- <p>
        <strong>Mitigation of Conflicts of Interest</strong>
    </p>
    <p>
         IMNE utilizes a peer review process to mitigate potential and actual
        conflicts of interest disclosed by all persons in a position to influence
        the planning process or content development of this educational activity.
        All peer reviewers are without relevant financial relationships. 
    </p> -->
    <p class="upp">
        <strong>Disclaimer</strong>
    </p>
    <p>
        This activity is designed for healthcare providers for educational
        purposes. Information and opinions offered by the faculty/presenters
        represent their own viewpoints. Conclusions drawn by the participants
        should be derived from careful consideration of all available scientific
        information.
    </p>
    <p>
        While IMNE makes every effort to have accurate information presented, no
        warranty, expressed or implied, is offered. The participant should use
        his/her clinical judgment, knowledge, experience, and diagnostic
        decision-making before applying any information, whether provided here or
        by others, for any professional use.
    </p>
    <p class="upp">
        <strong>Privacy and Confidentiality Statement</strong>
    </p>
    <p>
        IMNE will record learners’ personal information as provided on CME/CE
        evaluations to allow for issuance and tracking of CME/CE certificates. IMNE
        may also track aggregate responses to questions in activities and
        evaluations and use these data to inform the ongoing evaluation and
        improvement of its CME/CE program. No individual performance data or other
        personal information collected from evaluations will be shared with third
        parties.
    </p>
    <p class="upp">
        <strong>Commercial Support Acknowledgment</strong>
    </p>
    <p>
        This activity is supported by an educational grant from Novo Nordisk.
    </p>
    <p class="upp">
        <strong>Method of Participation</strong>
    </p>
    <p>
        To receive a maximum of 2.0 <em>AMA PRA Category 1 Credits</em> or 2.0
        nursing CE credits, participants should note the following:
    </p>
    <ul>
        <li>
            <p>
                Begin the activity by selecting a module to access a pretest (there
                are 3 modules in total)
            </p>
        </li>
        <li>
            <p>
                Complete the pretest
            </p>
        </li>
        <li>
            <p>
                Read and/or watch and/or listen to the content. Each module will
                take approximately 15 minutes to complete
            </p>
        </li>
        <li>
            <p>
                To claim CME/CE credit, you must complete the posttest
                corresponding to the module with a score of ≥ 80% and then complete
                the activity evaluation form for each activity
            </p>
        </li>
        <li>
            <p>
                Upon completion of each module, you will earn CME/CE credits
            </p>
        </li>
    </ul>
    <p>
        Note: To accumulate a total of 2.0 CME or CE credits for this activity,
        the participant must completely read/listen to/watch all 3 modules and
        complete the corresponding posttest and evaluation. Completion of each
        module activity will qualify the participant for 0.25 CME/CE credits, with
        completion of all 3 modules culminating in up to 2.0 CME/CE credits.
    </p>
    <p>
        <strong>
            The estimated time to complete all 3 module activities is 2.0 hours.
            For questions about the content or obtaining CME/CE credit, please
            contact Steve Weinman, MSc, RN, at
            <a
                href="https://basalknowledge.com/steve.weinman@imne.com"
                target="_blank"
            >
                steve.weinman@imne.com
            </a>
            .
        </strong>
    </p>
    <p>
        <strong>Minimum System Requirements</strong>
    </p>
    <p>
        The following system requirements are recommended for the best
        presentation-viewing experience:
    </p>
    <p>
        <u><strong>PC:</strong></u>
    </p>
    <ul>
        <li>
            <p>
                Microsoft Windows 8 or above
            </p>
        </li>
        <li>
            <p>
                Display resolution of 1024 × 768 pixels or greater
            </p>
        </li>
        <li>
            <p>
                Windows-compatible sound card
            </p>
        </li>
        <li>
            <p>
                Microsoft Internet Explorer 10 or above
            </p>
        </li>
        <li>
            <p>
                Windows Media Player 11
            </p>
        </li>
        <li>
            <p>
                Adobe Reader version 11 or above (
                <a href="http://get.adobe.com/reader/" target="_blank">
                    http://get.adobe.com/reader/
                </a>
                )
            </p>
        </li>
        <li>
            <p>
                Broadband Internet connection (512 kbps and above)
            </p>
        </li>
    </ul>
    <p>
        <u><strong>Mac:</strong></u>
    </p>
    <ul>
        <li>
            <p>
                Macintosh OS X 10.9 or above
            </p>
        </li>
        <li>
            <p>
                Display resolution of 1024 × 768 pixels or greater
            </p>
        </li>
        <li>
            <p>
                Safari version 8 or above
            </p>
        </li>
        <li>
            <p>
                Broadband Internet connection (512 kbps and above)
            </p>
        </li>
    </ul>
    <p>
        <u><strong>iPad:</strong></u>
    </p>
    <ul>
        <li>
            <p>
                iOS version 10 or above
            </p>
        </li>
    </ul>
  </div>


  </div>
</div>

<div class="row">
  <div class="col-md-4 offset-md-4 mt-5">
      <img class="img-fluid px-5 px-md-0 mb-5" *ngIf="!activityBtnDisabled" [routerLinkActive]="['active']" [routerLink]="['/login']" src="assets/images/buttons/enter-activity-btn.png" alt="Enter Activity">

  </div>
</div>
