import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import {
  IOption,
  ISelectedAnswer,
  ITestQuestion,
  ITestRequest,
  ITopic,
  ITopicStatusRequest,
} from '../../models/topic.model';
import { IUserStatus } from '../../models/user-status.model';
import { IUser } from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { TopicService } from '../../services/topic/topic.service';
import { UserService } from '../../services/user/user.service';
import { ModalLauncherService } from '../../services/modal-launcher/modal-launcher.service';
@Component({
  selector: 'app-posttest',
  templateUrl: './posttest.component.html',
  styleUrls: ['./posttest.component.scss'],
})
export class PosttestComponent implements OnInit, OnDestroy {
  scoreo: number;
  topicData: ITopic;
  questions: ITestQuestion[];
  topicId: number;
  questionId: number;
  testType: string;
  correctAnswers = false;
  correctButton = false;
  loading = false;
  submissionMessage: string;
  passing;
  correctA = false;
  user: IUser;
  userStatus: IUserStatus;
  userCurrentTopicId: number;
  answerArray: Array<ISelectedAnswer> = [];
  isChecked = false;
  isSubmitted = false;
  quizComplete = false;

  private subs: Subscription[] = [];

  constructor(
    private topicService: TopicService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalLauncherService
  ) {}

  ngOnInit() {
    this.subs.push(
      ...[this.subscribeToCurrentTopic(), this.subscribeToCurrentUser()]
    );
    this.validateUserTopic();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe);

    this.topicService.resetQuizValidation(this.questions);
  }

  subscribeToCurrentTopic() {
    return this.route.params.subscribe(params => {
      this.topicId = parseInt(params['topicId'], 10);
      this.topicData = this.topicService.getTopicById(this.topicId);
      this.questions = this.topicData.testQuestions;
    });
  }

  subscribeToCurrentUser() {
    return this.authService
      .getCurretUser()
      .take(1)
      .subscribe(user => {
        this.user = user.current;
        // this.userService.getCurrentUserstatus(this.user).subscribe(status => {
        //   this.userStatus = status;
        // });
      });
    return null;
  }

  validateUserTopic() {
    this.userService.topicSource.take(1).subscribe(id => {
      this.userCurrentTopicId = id;
    });
    if (this.userCurrentTopicId && this.userCurrentTopicId !== this.topicId) {
      this.userService.setUserTopic(this.userCurrentTopicId);
      this.router.navigate(['/posttest', this.userCurrentTopicId]);
    }
    return null;
  }

  onChange(question: ITestQuestion, questionOption: IOption) {
    this.answerArray = this.answerArray.filter(entry => {
      return entry.questionId !== question.id;
    });

    const questionEntryPost: ISelectedAnswer = {
      questionId: question.id,
      optionValue: questionOption.value,
    };
    this.answerArray.push(questionEntryPost);

    question.options = this.topicService.validateAnswer(
      question,
      questionOption
    );

    const score = question.options.find(x => x.selected === x.correct);
    const right = questionOption.value === question.answerId;
  }
  onViewRationale(id: number) {
    this.topicService.updateQuestionId(id);
    this.topicService.updateTestType('posttest');

    this.showRationaleModal(id);
  }

  showRationaleModal(id: number) {
    this.modalService.launchRationaleModal(
      this.questionId,
      this.testType,
      this.topicService.getRationaleByTestTypeAndQuestionId(
        this.topicId,
        id,
        this.testType
      )
    );
  }

  checkAnswers() {
    this.isSubmitted = true;
    let passing = this.scoreo;
    passing = this.topicService.calculateScore(this.questions);
    if (passing < 50) {
      this.correctA = true;
      this.correctButton = false;
    } else {
      this.correctA = false;
      this.correctButton = true;
    }
  }

  goToEvaluation() {
    this.router.navigate(['/evaluation', this.topicId]);
  }

  onSubmit() {
    this.isSubmitted = true;
     this.quizComplete = true;
    
    // const formSubmit: ITestRequest = {
    //     TopicId: this.topicData.TopicID,
    //     answers: this.answerArray.map(a => a.optionValue),
    //     date: new Date().toString(),
    //     user: {
    //       email: this.user.email,
    //       score: this.topicService.calculateScore(this.questions),
    //       userAccountId: this.user.userAccountID,
    //       username: this.user.username,
    //     },
    //   },
    //   topicStatusUpdate: ITopicStatusRequest = {
    //     TopicId: this.topicData.TopicID,
    //     Status: 'Incomplete',
    //     user: this.user,
    //   };
      this.goToEvaluation();
    // this.topicService
    //   .submitTopicTest(formSubmit, this.user.Token, 'tests')
    //   .subscribe(
    //     data => {
    //       this.topicService.submitTopicStatus(topicStatusUpdate).subscribe(
    //         data => {
    //           this.userService.updateUserTopicStatusByTopicId(
    //             this.userStatus,
    //             topicStatusUpdate.TopicId,
    //             'Active'
    //           );
    //           this.authService.updateUserToken(data, this.user);
    //            this.goToEvaluation();
    //         },
    //         error => {
    //           console.log('error updating user status');
    //           return Observable.throw(error);
    //         }
    //       );
    //     },
    //     error => {
    //       console.log('error submitting pretest');
    //       this.submissionMessage =
    //         'Sorry, there was an error submitting your test.  Please try again.';
    //       return Observable.throw(error);
    //     }
    //   );
  }
}
