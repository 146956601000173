import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

import { EmailService } from '../../services/email.service';
import { LoginContainer } from '../../containers/login.container';
import { ActivityContainer } from '../../containers/activity.container';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { LoggerService } from '../../services/logger.service';
import { UserLogin, User, UserActivity, EmailRequest } from '../../models';

@Component({
    selector: 'core-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {

    @Output() public goToRegistrationEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() public goToForgotPasswordEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() public onCancelFormEventEmitter: EventEmitter<boolean> = new EventEmitter();

    loginForm: UntypedFormGroup;
    submitted = false;
    loading = false;
    message: string;
    user: User;
    userstatustemp:UserActivity;
    constructor(
        private loginService: LoginService,
        private authService: AuthService,

        private activityContainer: ActivityContainer,
        private logger: LoggerService,
        private fb: UntypedFormBuilder,
        private loginContainer: LoginContainer,
        private router: Router

    ) {}

    ngOnInit() {
        this.buildForm();
    }

    buildForm(): void {
        this.loginForm = this.fb.group({
            email: [ '', [ Validators.required, Validators.email ] ],
            passwordHash: [ '', Validators.required ],
        });
    }

    buildLoginFormData(): UserLogin {
        return {
            Email: this.loginForm.controls.email.value,
            UserName: this.loginForm.controls.email.value,
            Password: this.loginForm.controls.passwordHash.value,
            IpAddress: '127.0.0.1',
            ApplicationId: environment.synApi.appId,
        }
    }

    onSubmit(): void {
        this.submitted = true;
        this.loading = true;
        this.message = null;
        this.loginService.login(this.buildLoginFormData())
        .pipe(take(1))
        .subscribe((user: User) => {
            this.user = user;
            this.user.SynUserId = this.user.UserId;
            this.loginContainer.updateUser(this.user);
            this.getActivityUserStatus(user);
            this.onResetForm()
        },
        error => {
               this.loginService.getUserByEmail(this.loginForm.controls.email.value)
                .pipe(take(1))
                .subscribe((user: User) => {

                  this.message = 'Sorry, there was an issue logging in. Please check your password and try again.';
                  this.submitted = false;
                  this.loading = false;
                  this.logger.logError(error);
                },
                error => {

                    this.message = 'Sorry, there was an issue logging in. Email was not found registered. Please register and create an account to continue.';
                    this.submitted = false;
                    this.loading = false;
                    this.logger.logError(error);
                });
        });

    }

    getActivityUserStatus(user: User) {
        this.loginService.getActivityUserStatus(user.SynUserId)
        .pipe(take(1))
            .subscribe((userStatus: UserActivity) => {
                userStatus.Level = '0';
                this.user.ActivityStatus = userStatus;
                this.user.UserId = this.user.ActivityStatus.UserActivityId;
                this.loginContainer.updateUser(this.user);
                this.onCancelForm();
                this.authService.loginUser();

            },
            error => {
                this.message = 'Sorry, there was an issue logging in. Please check your email and password and try again.';
                this.submitted = false;
                this.logger.logError(error);
                 console.log(error);
            });

    }

    onGoToRegister() {
        this.router.navigate(['/register']);
    }

    onGoToForgotPassword() {
        this.onResetForm();
        this.goToForgotPasswordEventEmitter.emit(true);
    }

    onCancelForm() {
        this.onResetForm();
        this.onCancelFormEventEmitter.emit(false);
    }

    onResetForm() {
        this.loading = false;
        this.submitted = false;
        this.loginForm.reset();
    }
}
