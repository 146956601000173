<div class="row mb-4">
  <div class="col-md-12">
    <div class=" character text-light flex-md-row mb-4 align-items-center">
      <video class="videolesson"  (ended)="onVideoEnded()" width="860" height="575" controls>
        <source *ngIf="vidone" src="../../../assets/images/videos/mod1.mp4" type="video/mp4">
        <source *ngIf="vidtwo" src="../../../assets/images/videos/mod2.mp4" type="video/mp4">
        <source *ngIf="vidthree" src="../../../assets/images/videos/mod3.mp4" type="video/mp4">

      </video>
            <div class="card-body character-body d-flex flex-column align-items-start">

        <h3 class="mb-0">
          {{ topicData.TopicID }}
        </h3>
        <p class="card-text lead mb-auto" [innerHTML]="topicData.caseDescription">
        </p>
      </div>

    </div>

    <div class="card card-accordion text-light">
      <ngb-accordion #acc="ngbAccordion">
        <div *ngFor="let stat of caseStats">
          <ngb-panel>
            <ng-template ngbPanelTitle>
              <div class="row">
                <div class="col-md-8 d-block mx-auto">
                  <div class="card-title text-light">
                    <span class="card-icon" [innerHTML]="stat.icon"></span>
                    <i class="fas fa-play fa-xs mr-2"></i>
                    <p class="d-inline-block m-0">{{ stat.title }}</p>
                  </div>
                </div>

              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row">
                <div class="col-md-8 d-block mx-auto">
                  <div class="case-stat" [innerHTML]="stat.content"></div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </div>
      </ngb-accordion>
    </div>


  </div>
</div>

<div class="row">
  <div class="col-md-10 mx-auto">


    <div *ngFor="let question of questions" class="test-form mb-5">

      <div class="ribbon-background mt-3 mb-5">
        <p class="lead" [innerHTML]="question.label"></p>
      </div>

      <div class="custom-control custom-checkbox mb-3" *ngFor="let option of question.options; let i = index" [ngClass]="{'correct': option.correct, 'wrong' : !option.correct && option.selected === true}">
        <input id="answerBox-{{question.id}}-{{i}}" class="custom-control-input" type="checkbox" (change)="onChange(question, option)"
          [(ngModel)]="option.selected" [ngClass]="{'correct': option.correct && isSubmitted && !question.opinion, 'wrong' : !option.correct && option.selected === true && isSubmitted && !question.opinion}">
        <label for="answerBox-{{question.id}}-{{i}}" class="pl-md-2 custom-control-label">{{i + 1}}.
          <span [innerHTML]="option.label"></span>
        </label>
      </div>
      <button *ngIf="isSubmitted" type="button" class="btn btn-outline-light my-4" (click)="onViewRationale(question.id)">View Rationale</button>
    </div>





    <button class="btn btn-secondary d-block mx-auto w-50 font-weight-bold" type="submit" (click)="goToPostTest()" [disabled]="answerArray.length < questions.length"
      *ngIf="isSubmitted">
      Continue
    </button>



  </div>
</div>
<br/>
<br/>