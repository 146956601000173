import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Activity, ActivityTest, ActivityTestResponse, Certificate } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    
    apiData = environment.synApi;
    activityApiData = environment.activityApi;

    constructor(
        private http: HttpClient,
    ) { }

    getActivityById(): Observable<Activity> {
        return this.http.get<Activity>(`${this.activityApiData.url}Activity/${ this.activityApiData.activityId }`)
    }

    getEvaluationByModuleId(moduleId: string): Observable<ActivityTest> {
        return this.http.get<ActivityTest>(`${this.activityApiData.url}Module/Evaluation?moduleId=${moduleId}`);
    }

    addOrUpdateModuleCertificate(certificateData: Certificate):Observable<Certificate> {
       return this.http.post<Certificate>(`${ this.activityApiData.url }Certificate/AddOrUpdate`, certificateData);
    }

    generateModuleCertificate(certificateId: string): Observable<any> {
        const httpOptions = {
            responseType: 'blob' as 'json'
        };

        return this.http.get(`${this.activityApiData.url}Certificate/Download?certificateId=${certificateId}`, httpOptions);
    }

    addActivityResponses(response: ActivityTestResponse): Observable<ActivityTestResponse> {
        return this.http.post <ActivityTestResponse>(`${this.activityApiData.url}Response/Add`, response);
    }
}