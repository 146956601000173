import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts,
} from 'angular-2-dropdown-multiselect';
import { Observable, pipe } from 'rxjs';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { SecurityQuestions } from '../../data/settings';
import {
  IClientRegisterRequest,
  IRegisterRequest,
  IUser,
} from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { Router } from '@angular/router';
import { User } from '../../models';
import { LoginContainer } from '../../containers/login.container';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  loading = false;
  submissionMessage: string;
  user: IUser;
  appUser: User;
  regFormGroup: UntypedFormGroup;
  firstNameControl: AbstractControl;
  lastNameControl: AbstractControl;
  emailControl: AbstractControl;
  usernameControl: AbstractControl;
  passwordControl: AbstractControl;
  UserSecurityQuestionIdControl: AbstractControl;
  SecurityAnswerControl: AbstractControl;
  ScheduleEmailControl: AbstractControl;
  model: any = {};
  securityQuestionMultislectSettings: IMultiSelectSettings = {
    enableSearch: false,
    closeOnSelect: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-block btn-multiselect',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
    closeOnClickOutside: true,
    autoUnselect: true,
    selectionLimit: 1,
    containerClasses: '',
  };
  securityQuestionMultislectTextSettings: IMultiSelectTexts = {
    defaultTitle: 'Select...',
  };
  securityQuestioOptions = SecurityQuestions.map(s => {
    return { id: s.id, name: s.title } as IMultiSelectOption;
  });

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private loginContainer: LoginContainer,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {}

  buildForm() {
    this.regFormGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      username: ['', Validators.required],
      password: ['', Validators.required],
      UserSecurityQuestionId: ['', Validators.required],
      SecurityAnswer: ['', Validators.required],
      ScheduleEmail: [true, Validators.required],
    });

    // easier access in template
    this.firstNameControl = this.regFormGroup.controls['firstName'];
    this.lastNameControl = this.regFormGroup.controls['lastName'];
    this.emailControl = this.regFormGroup.controls['email'];
    this.usernameControl = this.regFormGroup.controls['username'];
    this.passwordControl = this.regFormGroup.controls['password'];
    this.UserSecurityQuestionIdControl = this.regFormGroup.controls[
      'UserSecurityQuestionId'
    ];
    this.SecurityAnswerControl = this.regFormGroup.controls['SecurityAnswer'];
    this.ScheduleEmailControl = this.regFormGroup.controls['ScheduleEmail'];
  }

  registerActivityUser(synId: string) {
    this.authService.registerActivityUser(synId)
      .subscribe((data: any) => {
       this.appUser.ActivityStatus = data;
       this.appUser.UserId = data.UserId;
      // this.appUser.UserId =  this.appUser.ActivityStatus.UserActivityId;
       this.loginContainer.updateUser(this.appUser);
       this.authService.loginUser();
      });
  }

  onSubmit(formData: IClientRegisterRequest) {
    this.loading = false;
    formData = this.flattenSecurityQuestion(formData);

    const registerRequest: IRegisterRequest = {
      AvatarUrl: "",
      ApplicationId : "6702CF76-46C3-48D5-993D-71CCCA506E31",
      UserSecurityQuestionId: formData.UserSecurityQuestionId,
      SecurityAnswer: formData.SecurityAnswer,
      firstName: formData.firstName,
      lastName: formData.lastName,
      ScheduleEmail: formData.ScheduleEmail,
      email: formData.email,
      username: formData.username + 'fix',
      password: formData.password,
      salutation: '',
      mi: '',
      organization: '',
      jobTitle: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      countryID: 0,
      phone: '',
      fax: '',
      mobile: '',
      passwordChangeCode: '',
      usertypeid: 0,
      PictureUrl: 'meh.png',
      TimeZoneId: 'EST',
      PhoneNumber: '222-222-2222',
      UserName: formData.username,
      PasswordHash: formData.password,
      FirstName: formData.firstName,
      LastName: formData.lastName,
      IsEmailConfirmed: true,
    };
    const currentUser: IUser = {
      userAccountID: 1,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      hasOptedIn: true,
      Token: formData.email,
      username: formData.username,
      password: formData.password,
    };

    this.authService.registerUser(registerRequest).pipe(take(1))
    .subscribe(
      (data: any) => {
        this.appUser = data;
        this.appUser.SynUserId = this.appUser.UserId;
        this.loginContainer.updateUser(this.appUser);
        this.registerActivityUser( this.appUser.SynUserId);
        //  const currentUser: IUser = {
        //   userAccountID: 1,
        //   email: formData.email,
        //   firstName: formData.firstName,
        //   lastName: formData.lastName,
        //   hasOptedIn: true,
        //   Token: formData.email,
        //   username: formData.username,
        //   password: formData.password,
        // };

        this.submissionMessage =
          'Thank you for registering. Login now to start the activity';
            this.authService.setCurrentUser(currentUser);
        // this.userService.getCurrentUserstatus(currentUser).subscribe(
        //   status => {
        //     this.userService.setCurrentUserStatus(status);
        //     this.userService.getCurrentUserTopic(status);
        //   },
        //   error => {
        //     console.log('error gettng user status');
        //     return Observable.throw(error);
        //   }
        // );
       
        this.authService.setUserLoggedIn();
        localStorage.setItem('user'['current'], '1');
        this.regFormGroup.reset();
      //   setTimeout(() => {
      //     this.router.navigate(['/mymods']);
      //  }, 2000);
       
      },
      error => {
        console.log('error registering  user');
        this.submissionMessage =
          'Sorry, there was an error processing your registration.  Please try again.';
      }
    );
  }

  private flattenSecurityQuestion(formData) {
    if (formData && formData.UserSecurityQuestionId) {
      formData.UserSecurityQuestionId = Array.isArray(
        formData.UserSecurityQuestionId
      )
        ? formData.UserSecurityQuestionId.shift()
        : formData.UserSecurityQuestionId;

      return formData;
    }
  }
}
