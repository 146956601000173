import { Injectable, ɵɵsetComponentScope } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthContainer {

  apiData = environment.synApi;
  token: string;

  private appTokenSource = new BehaviorSubject<string>(null);
  appToken$ = this.appTokenSource.asObservable();

  constructor(
    private authService: AuthService,
  ) {
    this.subscribeToToken();
  }

  subscribeToToken() {
    this.appToken$.subscribe((token: string) => {
      this.token = token;
    });
  }

  updateAppToken(value: string) {
    this.appTokenSource.next(value);
  }

  getTokenAuthString(): string {
    return 'SYN ' + this.token;
  }

  authenticateApp(): Observable<string> {
    return this.authService.authenticateApp();
  }

  load(): Promise<any> {
    const promise = new Promise((resolve, reject) =>
      this.authService.authenticateApp()
        .subscribe((res) => {
          if (res) {
            this.updateAppToken(res.toString());
            resolve('ok');
          } else {
            reject();
          }
        }));
    return promise;
  }
}
