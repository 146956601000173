import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { environment } from '../../../environments/environment';
import {
  ILoginRequest,
  ILoginResponse,
  IUser,
  IRegisterRequest,
} from '../../models/user.model';
import { ResetUser, SetUser } from '../../state/actions/user.action';
import {
  ResetUserStatus,
  SetUserStatus,
} from '../../state/actions/user-status.action';
import { IUserStatus, IUserTopic } from '../../models/user-status.model';
import { ITestResponse } from '../../models/topic.model';
import { SetUserTopic, ResetUserTopic } from '../../state/actions/topic.action';
import { State } from '../../state/rootReducer';
import { UserService } from '../user/user.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class AuthService {
  loggedIn = false;

  apiData = environment.synApi;

  apiInfo = environment.api;

  activityApiData = environment.activityApi;


  userSource: BehaviorSubject<IUser> = new BehaviorSubject(null);
  user$ = this.userSource.asObservable();
  constructor(
    protected store: Store<State>,
    private http: HttpClient,
    private userService: UserService,
    private router: Router
  ) {
    this.loggedIn = !!localStorage.getItem('user'['current']);
  }

  ngOnDestroy() {
    localStorage.clear();
    //this.busquedasSubs.unsubscribe();
   }

  authenticateApp(): Observable<string> {
    return this.http.post<string>(`${this.apiData.authUrl}authenticate/${this.apiData.appId}/${this.apiData.signature}`, {}, { headers: new HttpHeaders().set('Authorization', `SYN ${this.apiData.signature}`) });
  }

  setUserLoggedIn() {
    this.loggedIn = true;
  }
  isUserLoggedIn() {
    return this.loggedIn;
  }

  getCurretUser() {
    return this.store.select(s => s.user);
  }

  setCurrentUser(user: IUser) {
    this.userSource.next(user);
    this.store.dispatch(new SetUser(user));
  }

  updateUserToken(requestResponse: ITestResponse, user: IUser): IUser {
    user.Token = requestResponse.Token;
    this.setCurrentUser(user);

    return user;
  }

  resetCurrentUser() {
    this.userSource.next(null);
    this.store.dispatch(new ResetUser());
    this.userService.resetUserTopicStatus();
  }

  logoutUser() {
    //localStorage.getItem('user'['current']) = null;
    this.resetCurrentUser();
    localStorage.clear();
    this.loggedIn =false;
    this.router.navigate(['/']);

  }

  

  loginUser()  {
    localStorage.setItem('user'['current'], '1');
    this.loggedIn =true;
   
    this.router.navigate(['/mymods']);
    //return this.http.post(`${ appiInfo.authUrl }User/Login`, loginRequest);
    //return null;
    // return this.http
    //   .post(
    //     appiInfo.url +
    //       'Authenticate?username=' +
    //       loginRequest.username +
    //       '&password=' +
    //       loginRequest.password,
    //     JSON.stringify(loginRequest),
    //     {
    //       headers: new Headers({ 'Content-Type': 'application/json' }),
    //     }
    //   )
    //   .map((res: Response) => res.json());
  }

  isAuthenticated() {}

  registerUser(registerRequest: IRegisterRequest) {
    let appiInfo = environment.synApi;
    registerRequest.IsEmailConfirmed = true;
    return this.http.post(`${ appiInfo.authUrl }User/Register?overrideKey=${ this.apiData.key }`, registerRequest);
  }

  loginActivityUser(creds) {
    return this.http.post(`${ this.apiData.authUrl }User/Login`, creds);
  }

  registerActivityUser(synId: string) {
    const user = {
      SynUserId: synId
    };

    return this.http.post(`${ this.activityApiData.url }User?overrideKey=`, user);

  }
}
