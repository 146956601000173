export const EvalModule1 = [
    {
        "Alias": "profession",
        "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 1,
        "Text": "Profession",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "c8e73b56-3607-4cc4-a714-a44d2377d025",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "MD",
                "SortOrder": 1,
                "IsCorrectAnswer": false,
            },
            {
                "AnswerId": "ef5e1ef7-f466-4f8e-a70b-6d0f93adc709",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "DO",
                "SortOrder": 2,
                "IsCorrectAnswer": false,
            },
            {
                "AnswerId": "c80d6394-b022-44c8-aaad-88ae3709fc5d",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "NP",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:35:36.5565679",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:35:36.5565679",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "3576f83f-5f92-40b2-9937-1e52a8296293",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "RN",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:35:36.5565679",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:35:36.5565679",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "1a2da20e-3b1e-485e-81b5-9163edfab61b",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "PA",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:35:36.5565679",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:35:36.5565679",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "262743b6-9054-40d9-a6ed-380c16caa5b3",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "CDE",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:35:36.5565679",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:35:36.5565679",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "00983adc-223d-4127-ab1b-ac021357b4b2",
                "QuestionId": "1da11adf-350b-4830-a9d7-cb16b26f849e",
                "Text": "Other",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 7,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:35:36.5565679",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:35:36.5565679",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            }
        ],
    },
    {
        "Alias": "specialty",
        "QuestionId": "97e4b877-f460-4eb3-889f-a11f660267a2",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 2,
        "GroupNumber": 1,
        "Text": "Specialty",
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "3c8ec520-3b6f-4a29-86a7-8a6636321a6b",
                "QuestionId": "97e4b877-f460-4eb3-889f-a11f660267a2",
                "Text": "",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:39:18.5299227",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:39:18.5299227",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            }
        ],
    },
    {
        "Alias": "avgPatient",
        "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 3,
        "GroupNumber": 1,
        "Text": "On average, how many patients with polycystic ovary syndrome (PCOS) do you see in 1 week?",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "52792285-2c5e-4160-a36a-3be3cb346ff8",
                "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
                "Text": "≤5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:42:35.9849325",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:42:35.9849325",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "5cdcc035-d3b9-4d92-9d61-345b3897ebd7",
                "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
                "Text": "6-15",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:42:35.9849325",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:42:35.9849325",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "6763936b-55e7-4adb-8518-5adac15277ee",
                "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
                "Text": "16-25",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:42:35.9849325",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:42:35.9849325",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "65a22447-adec-493c-ad00-55f894080877",
                "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
                "Text": "26-35",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:42:35.9849325",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:42:35.9849325",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "d89c1ec7-8cc7-4f6a-9e66-2f8572ea1ed0",
                "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
                "Text": "36-45",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:42:35.9849325",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:42:35.9849325",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            },
            {
                "AnswerId": "d2bc66dc-9aa1-4506-8a55-c96db1420d68",
                "QuestionId": "e43bb095-3292-4819-85b1-69be7d3f288b",
                "Text": ">45",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T01:42:35.9849325",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T01:42:35.9849325",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Unknown",
                "TypeId": 0,
                "StatusId": 0,
                "Links": null
            }
        ],
    },
    {
        "Alias": "time",
        "QuestionId": "232f78a8-58ad-4193-a3ff-6037e237b244",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 4,
        "GroupNumber": 2,
        "Text": "I certify that I completed this educational activity. The actual amount of time I spent in this activity was:",
        "Type": "selectlist",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "5502e0cb-963f-437b-9869-ceba7963bc2c",
                "QuestionId": "232f78a8-58ad-4193-a3ff-6037e237b244",
                "Text": "15 minutes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:28:11.4262829",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:28:11.4262829",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "98f19519-b110-4a38-adc9-cf1ca5f3ca3f",
                "QuestionId": "232f78a8-58ad-4193-a3ff-6037e237b244",
                "Text": "30 minutes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:28:11.688107",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:28:11.688107",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "certificateType",
        "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 5,
        "GroupNumber": 2,
        "Text": "CME/CE Certificate Type",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "74d5c07e-86b5-4b5e-bb69-06869dd9cb14",
                "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
                "Text": "MD/DO",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:42:57.4941055",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:42:57.4941055",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "bd332b56-2e72-43c5-b62c-cf517da7ecef",
                "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
                "Text": "PA",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:42:57.7467064",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:42:57.7467064",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "677e5702-70ea-437a-93e2-7f1cafe2882b",
                "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
                "Text": "NP",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:42:58.0092492",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:42:58.0092492",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "d6f1f727-5b99-4f11-b5e7-b1bc4dfc2817",
                "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
                "Text": "RN",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:42:58.2403298",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:42:58.2403298",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "332b7740-3af3-4750-81ac-6234f3e5fef8",
                "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
                "Text": "CDE",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:42:58.4735096",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:42:58.4735096",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "9495fc1b-ea53-4e04-9d5c-f8426b92bb8c",
                "QuestionId": "091dcf3b-b7c9-4988-b04a-37d74149593f",
                "Text": "Other",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T17:42:58.7217576",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T17:42:58.7217576",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "name",
        "QuestionId": "0e576e77-1d54-4998-87e1-a4dcedc1460e",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 6,
        "GroupNumber": 2,
        "Text": "Enter your full name (as it should appear on your certificate).",
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "4b46e2df-720f-4606-a0ba-078c657e15d0",
                "QuestionId": "0e576e77-1d54-4998-87e1-a4dcedc1460e",
                "Text": "---",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:00:06.8608547",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:00:06.8608547",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "evidenceBased",
        "QuestionId": "f52bd4ef-bc76-4854-8064-aeaa882479a6",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 7,
        "GroupNumber": 3,
        "Text": "Information provided in this presentation will promote the practice of evidence-based medicine",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "fd239091-f3c9-465c-b44b-09b73c3bad96",
                "QuestionId": "f52bd4ef-bc76-4854-8064-aeaa882479a6",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:48.4220087",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:48.4220087",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "a425cda6-4d79-43f4-b430-d42a1c0138a7",
                "QuestionId": "f52bd4ef-bc76-4854-8064-aeaa882479a6",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:48.6973299",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:48.6973299",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "efc4518c-b92e-43ff-b2dc-7e65972cb591",
                "QuestionId": "f52bd4ef-bc76-4854-8064-aeaa882479a6",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:48.9537158",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:48.9537158",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "2c08fd0f-402e-4030-9a15-7c735f478bb0",
                "QuestionId": "f52bd4ef-bc76-4854-8064-aeaa882479a6",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:49.2100039",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:49.2100039",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "8483776e-cd9f-465e-9cb9-e6c9bc479595",
                "QuestionId": "f52bd4ef-bc76-4854-8064-aeaa882479a6",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:49.4579224",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:49.4579224",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "biasFree",
        "QuestionId": "889f7cfc-ea2f-4900-85e5-014ea52aa178",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 8,
        "GroupNumber": 3,
        "Text": "The overall content of this activity was objective and free of bias.",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "a220bde5-add1-4c36-b81a-39bcfb0eb92d",
                "QuestionId": "889f7cfc-ea2f-4900-85e5-014ea52aa178",
                "Text": "Yes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:49.9383636",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:49.9383636",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "82b3a7d1-5d24-4dcf-9957-1402fc90542b",
                "QuestionId": "889f7cfc-ea2f-4900-85e5-014ea52aa178",
                "Text": "No",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:50.1993532",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:50.1993532",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "biasExplanation",
        "QuestionId": "02cd920c-2e4a-4bf0-8ad3-e8d30ffead75",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 9,
        "GroupNumber": 3,
        "Text": "If you answered \"no\" to the previous statement, please explain why.",
        "IsRequired": false,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "0f09f1dd-ea85-4a99-a921-9ecc08d3bb25",
                "QuestionId": "02cd920c-2e4a-4bf0-8ad3-e8d30ffead75",
                "Text": "----",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:50.6535426",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:50.6535426",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "describeGLP",
        "QuestionId": "30e7a958-ffa3-4fc6-8cc2-2a102c55c0d4",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 10,
        "GroupNumber": 4,
        "Text": "Describe the relationship between excess weight/obesity and women’s health (ie, PCOS, infertility, assisted reproduction techniques, urinary incontinence)",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "4da4c3e7-35b9-4998-bf09-a15f8cc4bdee",
                "QuestionId": "30e7a958-ffa3-4fc6-8cc2-2a102c55c0d4",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:51.0989754",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:51.0989754",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "55af7422-d8f7-439c-873b-fd16b6ef9665",
                "QuestionId": "30e7a958-ffa3-4fc6-8cc2-2a102c55c0d4",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:51.3513165",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:51.3513165",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "537083dc-b93b-4a4a-b136-e21c9c56e5d8",
                "QuestionId": "30e7a958-ffa3-4fc6-8cc2-2a102c55c0d4",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:51.603494",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:51.603494",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "d2f06241-bc78-46bf-9821-d54e33ef5738",
                "QuestionId": "30e7a958-ffa3-4fc6-8cc2-2a102c55c0d4",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:51.8497303",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:51.8497303",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "4c303889-7cb5-46c2-a7b2-d2ddfc24ef5e",
                "QuestionId": "30e7a958-ffa3-4fc6-8cc2-2a102c55c0d4",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:52.2290594",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:52.2290594",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "relevant",
        "QuestionId": "923cb4b9-6599-44eb-a620-4fddb805c753",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 11,
        "GroupNumber": 5,
        "Text": "The educational content was useful and relevant to my practice.",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "72cd2eb8-c743-417c-b61d-336ac754f77a",
                "QuestionId": "923cb4b9-6599-44eb-a620-4fddb805c753",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:52.8270704",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:52.8270704",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "1a7c0a15-f6dd-465a-9131-41c44074ca45",
                "QuestionId": "923cb4b9-6599-44eb-a620-4fddb805c753",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:53.1265861",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:53.1265861",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "df2b6ca8-7ad9-47e4-808c-6e12baeb06d6",
                "QuestionId": "923cb4b9-6599-44eb-a620-4fddb805c753",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:53.5846217",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:53.5846217",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "04b4ecf3-0c5f-454a-b9ba-4a61bab60bb6",
                "QuestionId": "923cb4b9-6599-44eb-a620-4fddb805c753",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:54.0297933",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:54.0297933",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "21d7029b-2e60-4869-8ac7-abb4681fecc2",
                "QuestionId": "923cb4b9-6599-44eb-a620-4fddb805c753",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:54.5080959",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:54.5080959",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "educational",
        "QuestionId": "aace1b87-551a-4999-8528-d5b554717deb",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 12,
        "GroupNumber": 5,
        "Text": "The educational format was effective.",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "169899cd-2280-4efe-a6be-4cf9e60e4560",
                "QuestionId": "aace1b87-551a-4999-8528-d5b554717deb",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:55.2433484",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:55.2433484",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "fc451459-53f2-44ed-8738-270101a3afe2",
                "QuestionId": "aace1b87-551a-4999-8528-d5b554717deb",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:55.6036232",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:55.6036232",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "8178e97f-5f0a-4126-8ca0-db955e1d8578",
                "QuestionId": "aace1b87-551a-4999-8528-d5b554717deb",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:55.929818",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:55.929818",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "d522f546-249d-4b3a-af77-a2ba878ea09e",
                "QuestionId": "aace1b87-551a-4999-8528-d5b554717deb",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:56.2091587",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:56.2091587",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "e1b1dd77-7060-4d8f-9c5f-ab34307855b9",
                "QuestionId": "aace1b87-551a-4999-8528-d5b554717deb",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:56.4759037",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:56.4759037",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "knowledge",
        "QuestionId": "ef0d0d9f-8d70-42f6-9628-8bd22b427dfa",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 13,
        "GroupNumber": 5,
        "Text": "The information provided in this presentation enhanced my knowledge and will enable me to provide better care for my patients with PCOS.",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "27f34c81-53ca-4a33-b0f1-57d46c3816cf",
                "QuestionId": "ef0d0d9f-8d70-42f6-9628-8bd22b427dfa",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:57.0188559",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:57.0188559",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "f8ed53be-9878-410f-ad27-d68764a81793",
                "QuestionId": "ef0d0d9f-8d70-42f6-9628-8bd22b427dfa",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:57.2610161",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:57.2610161",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "edb1a48e-30b7-42fa-b1a7-c604d675e9b2",
                "QuestionId": "ef0d0d9f-8d70-42f6-9628-8bd22b427dfa",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:57.5139148",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:57.5139148",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "13eda86c-3b1e-4339-9a02-9614e35ccf2c",
                "QuestionId": "ef0d0d9f-8d70-42f6-9628-8bd22b427dfa",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:57.8547626",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:57.8547626",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "476c46c8-6e5c-4c1f-b789-2fd3c3cf76ea",
                "QuestionId": "ef0d0d9f-8d70-42f6-9628-8bd22b427dfa",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:58.1019348",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:58.1019348",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "confidence",
        "QuestionId": "cd98be94-8ad0-4826-9d37-6f68ad7ad586",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 14,
        "GroupNumber": 5,
        "Text": "The information provided in this presentation enhanced my confidence in treating PCOS.",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "eb180921-b6ef-4cae-9b67-61f84c8d20a3",
                "QuestionId": "cd98be94-8ad0-4826-9d37-6f68ad7ad586",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:58.5694637",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:58.5694637",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "4c3d2cf7-a1b7-4766-8bd6-aea888168877",
                "QuestionId": "cd98be94-8ad0-4826-9d37-6f68ad7ad586",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:58.8175759",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:58.8175759",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "d2ee0529-b653-4fd9-86ea-b5c126091617",
                "QuestionId": "cd98be94-8ad0-4826-9d37-6f68ad7ad586",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:59.0657992",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:59.0657992",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "71c883d3-b70a-444b-824e-e295747d2ab3",
                "QuestionId": "cd98be94-8ad0-4826-9d37-6f68ad7ad586",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:59.3270083",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:59.3270083",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "8aaf111c-9c84-43ee-b2bf-8fb003598dc0",
                "QuestionId": "cd98be94-8ad0-4826-9d37-6f68ad7ad586",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:33:59.5914833",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:33:59.5914833",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "unansweredQuestion",
        "QuestionId": "14dc86f6-f8c9-45ec-a67a-379be3459158",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 15,
        "GroupNumber": 5,
        "Text": "What one question remains unanswered after having participated in this activity?",
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "0d2346e2-da54-4a3a-9e03-c57ab4827660",
                "QuestionId": "14dc86f6-f8c9-45ec-a67a-379be3459158",
                "Text": "---",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:34:00.0467085",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:34:00.0467085",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "infoLearned",
        "QuestionId": "677b0cce-0c43-48f2-9451-57c44f5df67c",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 16,
        "GroupNumber": 5,
        "Text": "Please cite one new piece of information that you learned from this activity.",
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "bdcee2ad-0e83-4ce0-8023-a7be6c1effe0",
                "QuestionId": "677b0cce-0c43-48f2-9451-57c44f5df67c",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:34:00.5057502",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:34:00.5057502",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "relatedTopics",
        "QuestionId": "55f991d6-4209-4a86-bfe4-a1d7190a4723",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 17,
        "GroupNumber": 5,
        "Text": "What topics related to diabetes would you like to see offered in future continuing education activities?",
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "bf73b4fd-2f77-4893-8fa9-ae7d8434b6c9",
                "QuestionId": "55f991d6-4209-4a86-bfe4-a1d7190a4723",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:34:00.974701",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:34:00.974701",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "commitment",
        "QuestionId": "9e352494-cc45-4f8c-a75b-2c72e8100ed7",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 18,
        "GroupNumber": 6,
        "Text": "After participating in this activity, I am committed to changing the way I treat my patients with PCOS.",
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "b0575c15-8c3e-4d73-ae47-4e6865c5058e",
                "QuestionId": "9e352494-cc45-4f8c-a75b-2c72e8100ed7",
                "Text": "Yes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:34:01.4547135",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:34:01.4547135",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "de670354-951c-4eae-a15f-a4d0e1025aa5",
                "QuestionId": "9e352494-cc45-4f8c-a75b-2c72e8100ed7",
                "Text": "No",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:34:01.7001769",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:34:01.7001769",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "commitmentDescription",
        "QuestionId": "2002c26e-f52a-4b48-ac6d-db2f09c53225",
        "ActivityTestId": "7b60a02f-6e50-4d50-92f3-b56c271d6804",
        "SortOrder": 19,
        "GroupNumber": 6,
        "Text": "If yes, please describe one thing you plan to change in your practice as a result of this activity.",
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "92407477-3a9b-44b3-921a-7323c6524085",
                "QuestionId": "2002c26e-f52a-4b48-ac6d-db2f09c53225",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:34:02.1589942",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:34:02.1589942",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    }
]

export const EvalModule2 = [
    {
        "Alias": "profession",
        "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 1,
        "GroupNumber": 1,
        "Text": "Profession",
        "Type": "radiobutton",
        "ResponseId": null,
        "IsRequired": true,
        "Answers": [
            {
                "AnswerId": "5f6ea183-d651-46c9-8897-66be008cc794",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "MD",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:11.5361115",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:11.5361115",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "f512968e-ab5b-452c-8e78-9a4bfe2cc83c",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "DO",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:11.8059637",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:11.8059637",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "d3ec759d-adb5-484b-9621-48ebf41276eb",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "NP",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:12.0538603",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:12.0538603",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "ac70a70c-02e2-453f-9409-407416a867e8",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "RN",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:12.3057782",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:12.3057782",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "0cf43e53-4ebb-4c2f-a6c8-5aa2a72b58a2",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "PA",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:12.5488237",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:12.5488237",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "34babd65-e746-4f9a-b627-cf9edb9e5c9d",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "CDE",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:12.805771",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:12.805771",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "0d2a86fe-5652-4694-968b-6995d6c5c65d",
                "QuestionId": "32ced349-2172-43f0-a9ae-5e0025cfc04c",
                "Text": "Other",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 7,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:13.0623012",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:13.0623012",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "specialty",
        "QuestionId": "2c21c4e4-5580-4956-9b53-a0847421445e",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 2,
        "GroupNumber": 1,
        "Text": "Specialty",
        "Type": "textarea",
        "ResponseId": null,
        "IsRequired": true,
        "Answers": [
            {
                "AnswerId": "90e1fd63-c512-43ef-a87e-5cd77e464c31",
                "QuestionId": "2c21c4e4-5580-4956-9b53-a0847421445e",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:13.5134159",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:13.5134159",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    },
    {
        "Alias": "avgPatient",
        "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 3,
        "GroupNumber": 1,
        "Text": "On average, how many patients with polycystic ovary syndrome (PCOS) do you see in 1 week?",
        "Type": "radiobutton",
        "ResponseId": null,
        "IsRequired": true,
        "Answers": [
            {
                "AnswerId": "a8070fc2-f5ea-425e-91db-68a52dc2c7a9",
                "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
                "Text": "≤5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:13.9741926",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:13.9741926",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "394eb371-45de-45da-aadc-d90ad74fca08",
                "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
                "Text": "6-15",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:14.2324923",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:14.2324923",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "803a368c-ea8a-4a15-8266-f9d9273d0bc9",
                "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
                "Text": "16-25",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:14.478976",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:14.478976",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "2d6cdc83-b80e-4157-bc97-ddaef2ea7d22",
                "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
                "Text": "26-35",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:14.7266665",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:14.7266665",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "e3e71ec4-8b41-41df-ae24-6c8d67ac677e",
                "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
                "Text": "36-45",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:14.9734442",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:14.9734442",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "aec2a7af-201d-4931-aa07-255d95032d3a",
                "QuestionId": "d5620e9f-f578-48a7-a370-db04e501943a",
                "Text": ">45",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:15.233286",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:15.233286",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "time",
        "QuestionId": "da592f46-631d-4f53-925b-163cdf7083b9",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 4,
        "GroupNumber": 2,
        "Text": "I certify that I completed this educational activity. The actual amount of time I spent in this activity was",
        "Type": "selectlist",
        "ResponseId": null,
        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "b476b8a2-45aa-4eab-aa7a-8d63305638e2",
                "QuestionId": "da592f46-631d-4f53-925b-163cdf7083b9",
                "Text": "15 minutes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:15.6812566",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:15.6812566",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "d26ce7c0-20d6-4593-9262-c74237c78eae",
                "QuestionId": "da592f46-631d-4f53-925b-163cdf7083b9",
                "Text": "30 minutes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:15.9369425",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:15.9369425",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "06d6017f-ae1f-4384-bd9c-b88ca9d80793",
                "QuestionId": "da592f46-631d-4f53-925b-163cdf7083b9",
                "Text": "45 minutes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:16.1934766",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:16.1934766",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "certificateType",
        "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 5,
        "GroupNumber": 2,
        "Text": "CME/CE Certificate Type",
        "Type": "radiobutton",
        "ResponseId": null,
        "IsRequired": true,
        "Answers": [
            {
                "AnswerId": "8936891e-ab6c-4ed6-8cba-fffe3f4c3a8b",
                "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
                "Text": "MD/DO",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:17.0293722",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:17.0293722",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "68ef3c44-55a8-4674-9001-56cfb790ceeb",
                "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
                "Text": "PA",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:17.4034258",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:17.4034258",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "7049df96-c0f3-4142-b8c2-6dad26870bb2",
                "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
                "Text": "NP",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:17.7787538",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:17.7787538",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "a72f2d12-0867-4ae2-9eff-e7b21385e018",
                "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
                "Text": "RN",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:18.1821961",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:18.1821961",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "1dd9b143-9623-47d3-92ab-6323f10c1c7b",
                "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
                "Text": "CDE",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:18.5349695",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:18.5349695",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "5f2928af-f0d9-4db7-9212-beb431576f11",
                "QuestionId": "bdb6f5e0-ee20-421c-ad8c-9b70eb53f68c",
                "Text": "Other",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:18.8021307",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:18.8021307",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "name",
        "QuestionId": "7454f4f2-9040-4f88-b2fd-cfa359ccbd89",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 6,
        "GroupNumber": 2,
        "Text": "Enter your full name (as it should appear on your certificate).",
        "Type": "textarea",
        "ResponseId": null,
        "IsRequired": true,
        "Answers": [
            {
                "AnswerId": "471b2aa4-8881-4250-a286-ff0b433bfc6a",
                "QuestionId": "7454f4f2-9040-4f88-b2fd-cfa359ccbd89",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:19.3660934",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:19.3660934",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "evidenceBased",
        "QuestionId": "2db08fb2-3e6f-4856-8d49-db2ba134731e",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 7,
        "GroupNumber": 3,
        "Text": "Information provided in this presentation will promote the practice of evidence-based medicine",
        "Type": "radiobutton",
        "ResponseId": null,
        "IsRequired": true,
        "Answers": [
            {
                "AnswerId": "93acd413-9f83-474d-b6a7-6dc2ce2e22a9",
                "QuestionId": "2db08fb2-3e6f-4856-8d49-db2ba134731e",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:19.8140243",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:19.8140243",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "e909342b-92a5-488e-8731-1244f7cb139a",
                "QuestionId": "2db08fb2-3e6f-4856-8d49-db2ba134731e",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:20.5322592",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:20.5322592",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "df4d050e-27c3-43c1-952c-229810de86df",
                "QuestionId": "2db08fb2-3e6f-4856-8d49-db2ba134731e",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:20.8663295",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:20.8663295",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "732ce81b-592c-4433-a48b-47d4247cc362",
                "QuestionId": "2db08fb2-3e6f-4856-8d49-db2ba134731e",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:21.1223308",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:21.1223308",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "45cbd1a4-67cf-45e6-8c17-6f7e81604584",
                "QuestionId": "2db08fb2-3e6f-4856-8d49-db2ba134731e",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:21.3746201",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:21.3746201",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "biasFree",
        "QuestionId": "6a69c7a2-a738-4c92-bd93-4bccb3bb9ec3",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 8,
        "GroupNumber": 3,
        "Text": "The overall content of this activity was objective and free of bias.",
        "Type": "radiobutton",
        "ResponseId": null,
        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "de913ac3-f710-48e5-b443-13a73c297609",
                "QuestionId": "6a69c7a2-a738-4c92-bd93-4bccb3bb9ec3",
                "Text": "Yes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:21.8444087",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:21.8444087",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "ba73f93d-1311-468c-8405-6322ac28bac6",
                "QuestionId": "6a69c7a2-a738-4c92-bd93-4bccb3bb9ec3",
                "Text": "No",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:22.1246238",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:22.1246238",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    },
    {
        "Alias": "biasExplanation",
        "QuestionId": "245dcdf8-0669-4b4f-81cd-acf2ddb10c2e",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 9,
        "GroupNumber": 3,
        "Text": "If you answered \"no\" to the previous statement, please explain why.",
        "Type": "textarea",
        "ResponseId": null,

        "IsRequired": false,

        "Answers": [
            {
                "AnswerId": "d51b8dcf-df49-48d2-b1a4-444f96ff883d",
                "QuestionId": "245dcdf8-0669-4b4f-81cd-acf2ddb10c2e",
                "Text": "----",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:22.5730534",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:22.5730534",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],
    },
    {
        "Alias": "describeGLP",
        "QuestionId": "1fdd148c-3f30-4f2d-9e08-95d7a2cb00d2",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 10,
        "GroupNumber": 4,
        "Text": "Implement behavioral intervention approaches for discussing weight loss goals and treatment approaches",
        "Type": "radiobutton",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "498ba026-c35d-4681-9d98-9b9db6ffd14d",
                "QuestionId": "1fdd148c-3f30-4f2d-9e08-95d7a2cb00d2",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:23.0530302",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:23.0530302",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "4447416b-6b4a-4551-8dd1-f2346e7bd86d",
                "QuestionId": "1fdd148c-3f30-4f2d-9e08-95d7a2cb00d2",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:23.3308806",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:23.3308806",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "333fff45-b4e2-42d1-8682-4e3fe1f95724",
                "QuestionId": "1fdd148c-3f30-4f2d-9e08-95d7a2cb00d2",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:23.5847425",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:23.5847425",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "3c6ccef1-4bc1-4a28-a5be-6e86ecb0bd91",
                "QuestionId": "1fdd148c-3f30-4f2d-9e08-95d7a2cb00d2",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:23.8542403",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:23.8542403",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "0a7208a8-e251-4dce-9333-d8d4bed02361",
                "QuestionId": "1fdd148c-3f30-4f2d-9e08-95d7a2cb00d2",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:24.1016574",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:24.1016574",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    },
    {
        "Alias": "relevant",
        "QuestionId": "10ef47c6-64ea-470d-b9b4-d5150961c09c",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 11,
        "GroupNumber": 5,
        "Text": "The educational content was useful and relevant to my practice.",
        "Type": "radiobutton",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "db3cdad1-d93d-4559-b8a9-cd317aa69b58",
                "QuestionId": "10ef47c6-64ea-470d-b9b4-d5150961c09c",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:24.5330302",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:24.5330302",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "ad84fb1a-fe00-41c2-807a-9fd04002d3ad",
                "QuestionId": "10ef47c6-64ea-470d-b9b4-d5150961c09c",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:24.7931718",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:24.7931718",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "b255ee7e-7ca9-46f6-9d09-5424fffb6c13",
                "QuestionId": "10ef47c6-64ea-470d-b9b4-d5150961c09c",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:25.0614088",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:25.0614088",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "71692273-b37b-4f81-9fc1-80fb5232a3eb",
                "QuestionId": "10ef47c6-64ea-470d-b9b4-d5150961c09c",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:25.3148511",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:25.3148511",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "2a5c5eb2-4bab-4018-a651-d19576b31ebe",
                "QuestionId": "10ef47c6-64ea-470d-b9b4-d5150961c09c",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:25.5745966",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:25.5745966",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    },
    {
        "Alias": "educational",
        "QuestionId": "944f1668-5dd0-454b-b8cf-fb001ea740e8",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 12,
        "GroupNumber": 5,
        "Text": "The educational format was effective.",
        "Type": "radiobutton",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "4e6248f0-4e0e-4d19-befa-ade9d07f3a3e",
                "QuestionId": "944f1668-5dd0-454b-b8cf-fb001ea740e8",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:26.6888531",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:26.6888531",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "0781b6fc-4511-47d7-8561-6a1a107e46c2",
                "QuestionId": "944f1668-5dd0-454b-b8cf-fb001ea740e8",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:26.9577102",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:26.9577102",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "6f943f0c-8e0f-4d8b-80e6-43f914a5e744",
                "QuestionId": "944f1668-5dd0-454b-b8cf-fb001ea740e8",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:27.2071542",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:27.2071542",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "f6dd726b-c93f-4a27-9023-7718af449049",
                "QuestionId": "944f1668-5dd0-454b-b8cf-fb001ea740e8",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:27.4863515",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:27.4863515",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "59dc42b8-1a70-4049-b71f-b982beb91c19",
                "QuestionId": "944f1668-5dd0-454b-b8cf-fb001ea740e8",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:27.7305035",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:27.7305035",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],


    },
    {
        "Alias": "knowledge",
        "QuestionId": "910d1cc1-96f3-4340-acd6-248704923b75",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 13,
        "GroupNumber": 5,
        "Text": "The information provided in this presentation enhanced my knowledge and will enable me to provide better care for my patients with PCOS.",
        "Type": "radiobutton",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "35841bbc-faf8-4e6f-8c8b-c8f756b7591b",
                "QuestionId": "910d1cc1-96f3-4340-acd6-248704923b75",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:28.2661418",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:28.2661418",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "01759004-cfae-4727-b02d-c468a7fe8f6f",
                "QuestionId": "910d1cc1-96f3-4340-acd6-248704923b75",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:28.5255459",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:28.5255459",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "88677487-3cce-44e6-8207-be0de01ae34d",
                "QuestionId": "910d1cc1-96f3-4340-acd6-248704923b75",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:28.7649495",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:28.7649495",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "7910768f-c75e-4736-8834-01474300b243",
                "QuestionId": "910d1cc1-96f3-4340-acd6-248704923b75",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:29.0452371",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:29.0452371",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "8b5dcecb-0208-43f5-86a9-74ae92daddf9",
                "QuestionId": "910d1cc1-96f3-4340-acd6-248704923b75",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:29.3273178",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:29.3273178",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],


    },
    {
        "Alias": "confidence",
        "QuestionId": "71eb96a8-5ebf-4f60-80d6-d8d98eda1aaa",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 14,
        "GroupNumber": 5,
        "Text": "The information provided in this presentation enhanced my confidence in treating PCOS.",
        "Type": "radiobutton",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "8aa7ba5e-3d55-40b4-a491-8ac85f3f759b",
                "QuestionId": "71eb96a8-5ebf-4f60-80d6-d8d98eda1aaa",
                "Text": "1",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:29.7890693",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:29.7890693",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "9f8c6363-80e7-4698-8f30-0e61308d585a",
                "QuestionId": "71eb96a8-5ebf-4f60-80d6-d8d98eda1aaa",
                "Text": "2",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:30.0382524",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:30.0382524",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "be0896da-db33-4f2c-b2cd-50977a97fe57",
                "QuestionId": "71eb96a8-5ebf-4f60-80d6-d8d98eda1aaa",
                "Text": "3",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:30.2868234",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:30.2868234",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "62a0dcf6-64a1-4f41-9e23-3f0b3f14e1e7",
                "QuestionId": "71eb96a8-5ebf-4f60-80d6-d8d98eda1aaa",
                "Text": "4",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:30.5348427",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:30.5348427",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "b16bfd3a-3d45-4a97-9686-4c633052db1f",
                "QuestionId": "71eb96a8-5ebf-4f60-80d6-d8d98eda1aaa",
                "Text": "5",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:30.7821548",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:30.7821548",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],


    },
    {
        "Alias": "unansweredQuestion",
        "QuestionId": "117e2742-a0b4-4e7c-85e2-4ec70d0ca37f",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 15,
        "GroupNumber": 5,
        "Text": "What one question remains unanswered after having participated in this activity?",
        "Type": "textarea",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "c02bbac0-8454-4636-865f-23d5c1b2c9b3",
                "QuestionId": "117e2742-a0b4-4e7c-85e2-4ec70d0ca37f",
                "Text": "---",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:31.2458269",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:31.2458269",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    },
    {
        "Alias": "infoLearned",
        "QuestionId": "3d178602-1241-4abb-a69e-f26571cfb799",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 16,
        "GroupNumber": 5,
        "Text": "Please cite one new piece of information that you learned from this activity.",
        "Type": "textarea",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "ab12b0ba-94da-42de-b81a-312bb638dad3",
                "QuestionId": "3d178602-1241-4abb-a69e-f26571cfb799",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:31.9108442",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:31.9108442",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],


    },
    {
        "Alias": "relatedTopics",
        "QuestionId": "a0b86b05-3ee2-46ef-a5cc-a27f0d500bc8",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 17,
        "GroupNumber": 5,
        "Text": "What topics related to diabetes would you like to see offered in future continuing education activities?",
        "Type": "textarea",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "a745456c-bacf-41eb-a19b-d1527318c523",
                "QuestionId": "a0b86b05-3ee2-46ef-a5cc-a27f0d500bc8",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:32.3662437",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:32.3662437",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    },
    {
        "Alias": "commitment",
        "QuestionId": "2ad2e8b0-8b7b-42bb-8ef3-a3ed63215b01",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 18,
        "GroupNumber": 6,
        "Text": "After participating in this activity, I am committed to changing the way I treat my patients with PCOS.",
        "Type": "radiobutton",
        "ResponseId": null,

        "IsRequired": true,

        "Answers": [
            {
                "AnswerId": "5009c096-fad7-4dd4-beb1-32aec966d2ac",
                "QuestionId": "2ad2e8b0-8b7b-42bb-8ef3-a3ed63215b01",
                "Text": "Yes",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:32.8388708",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:32.8388708",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            },
            {
                "AnswerId": "17c9b703-4cdb-43dd-ae56-163f66d883dd",
                "QuestionId": "2ad2e8b0-8b7b-42bb-8ef3-a3ed63215b01",
                "Text": "No",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:33.1164843",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:33.1164843",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],


    },
    {
        "Alias": "commitmentDescription",
        "QuestionId": "0a24ed37-28b9-4491-a65f-f91f7458ce91",
        "ActivityTestId": "6df2c7b0-ccee-4539-849a-746c0775f0b2",
        "SortOrder": 19,
        "GroupNumber": 6,
        "Text": "If yes, please describe one thing you plan to change in your practice as a result of this activity.",
        "Type": "textarea",
        "ResponseId": null,

        "IsRequired": false,

        "Answers": [
            {
                "AnswerId": "a05bf7a3-2506-4b22-9e51-6bca4b80d9f5",
                "QuestionId": "0a24ed37-28b9-4491-a65f-f91f7458ce91",
                "Text": "--",

                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:54:33.5932776",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:54:33.5932776",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,

            }
        ],

    }
]

export const EvalModule3 = [
    {
        "Alias": "profession",
        "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 1,
        "GroupNumber": 1,
        "Text": "Profession",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "22ad0d5f-aa16-4ee4-a6ac-a7d6b246ba38",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "MD",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:53.108333",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:53.108333",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "de81b056-9628-431b-8d88-5bc747cd845c",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "DO",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:53.4064472",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:53.4064472",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ec0dab35-06d4-486d-97c9-5295724ac7dd",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "NP",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:53.7139749",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:53.7139749",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "4a5ddc14-3a04-42ce-8ddf-e5ba67e75306",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "RN",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:54.0309758",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:54.0309758",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "568d176c-d354-4c76-9dd6-d0b40d280326",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "PA",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:54.3387487",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:54.3387487",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "a61d5773-3446-4c41-8e7d-2f225d13b1c3",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "CDE",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:54.6029812",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:54.6029812",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "9a02633f-d81f-4e3d-bdd0-21f1c403e5fb",
                "QuestionId": "6d93b703-f213-4db3-bc56-41abc9e3fd85",
                "Text": "Other",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 7,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:54.8508793",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:54.8508793",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "specialty",
        "QuestionId": "319249ed-f97e-471a-b692-706ba4f3c00e",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 2,
        "GroupNumber": 1,
        "Text": "Specialty",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "ec7d576c-c0dd-4fd1-ba81-63ef3e0461e3",
                "QuestionId": "319249ed-f97e-471a-b692-706ba4f3c00e",
                "Text": "--",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:55.3306624",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:55.3306624",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "avgPatient",
        "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 3,
        "GroupNumber": 1,
        "Text": "On average, how many patients with polycystic ovary syndrome (PCOS) do you see in 1 week?",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "d03bb8ef-482f-47fd-b268-83c731485ddd",
                "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
                "Text": "≤5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:55.8139866",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:55.8139866",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "8d69aabb-cfd9-4f9e-aa07-867a15c0633b",
                "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
                "Text": "6-15",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:56.0583869",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:56.0583869",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "7e294e95-8249-478d-9912-5f9c8f6829b7",
                "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
                "Text": "16-25",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:56.3145835",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:56.3145835",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "97c08a81-6557-41d3-9eda-0c43c1cda931",
                "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
                "Text": "26-35",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:56.5668932",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:56.5668932",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "1e09cd14-67bd-4617-a28b-29525fc6082d",
                "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
                "Text": "36-45",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:56.8255797",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:56.8255797",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "255eca65-0713-4bc8-b108-9f300fc54ac4",
                "QuestionId": "b7e60b8d-8314-4a64-947c-f33b37214e05",
                "Text": ">45",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:57.0834598",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:57.0834598",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "time",
        "QuestionId": "bfe22cb7-e1df-4700-85eb-a9728eab8fdb",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 4,
        "GroupNumber": 2,
        "Text": "I certify that I completed this educational activity. The actual amount of time I spent in this activity was",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "selectlist",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "ed5d7fb4-cdf6-4a89-b677-24c91d2cf958",
                "QuestionId": "bfe22cb7-e1df-4700-85eb-a9728eab8fdb",
                "Text": "15 minutes",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:57.5453617",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:57.5453617",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ee832695-b200-45ab-b9fe-5ef26cf77538",
                "QuestionId": "bfe22cb7-e1df-4700-85eb-a9728eab8fdb",
                "Text": "30 minutes",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:57.8056111",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:57.8056111",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "d68aa040-8eb4-4242-b474-149bfa739c5f",
                "QuestionId": "bfe22cb7-e1df-4700-85eb-a9728eab8fdb",
                "Text": "45 minutes",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:58.0516775",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:58.0516775",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "certificateType",
        "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 5,
        "GroupNumber": 2,
        "Text": "CME/CE Certificate Type",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "37b368bf-612f-43e2-9b27-9ab5538b51e1",
                "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
                "Text": "MD/DO",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:58.6524044",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:58.6524044",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "21d7e137-66f7-4931-8718-8792fb4f1b84",
                "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
                "Text": "PA",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:58.9326779",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:58.9326779",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "c0109da1-3b96-41bc-b134-93875675cf1b",
                "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
                "Text": "NP",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:59.1881069",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:59.1881069",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "9fe22fb5-cbb1-4403-9b76-45dafded414c",
                "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
                "Text": "RN",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:59.5740505",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:59.5740505",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "f2f47a96-516c-43c5-a344-3678fc5a9331",
                "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
                "Text": "CDE",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T18:59:59.8222824",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T18:59:59.8222824",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "89be191c-89c9-43cc-9b45-c229cb739faf",
                "QuestionId": "7d746d7e-d16f-4594-9d32-e5549af0881b",
                "Text": "Other",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 6,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:00.0727409",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:00.0727409",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "name",
        "QuestionId": "93510d4a-89d3-4cc8-a8b2-9e15a3138604",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 6,
        "GroupNumber": 2,
        "Text": "Enter your full name (as it should appear on your certificate).",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "e216978f-1b2d-4bc4-9149-e54ec65d4f37",
                "QuestionId": "93510d4a-89d3-4cc8-a8b2-9e15a3138604",
                "Text": "--",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:00.5204",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:00.5204",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "evidenceBased",
        "QuestionId": "e8f4ac1a-8277-4663-a1a8-4bdd219941af",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 7,
        "GroupNumber": 3,
        "Text": "Information provided in this presentation will promote the practice of evidence-based medicine",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "ba81fa17-af70-46b4-8723-70c40da7d832",
                "QuestionId": "e8f4ac1a-8277-4663-a1a8-4bdd219941af",
                "Text": "1",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:01.0134436",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:01.0134436",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "c9f1a451-8075-43a9-8dc7-fa28d52254aa",
                "QuestionId": "e8f4ac1a-8277-4663-a1a8-4bdd219941af",
                "Text": "2",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:01.273808",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:01.273808",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "03abdac1-bba9-4164-ac8d-59e23ecd1429",
                "QuestionId": "e8f4ac1a-8277-4663-a1a8-4bdd219941af",
                "Text": "3",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:01.5344518",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:01.5344518",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "528a8ff0-de0e-4ec8-9581-9ba38d4bb379",
                "QuestionId": "e8f4ac1a-8277-4663-a1a8-4bdd219941af",
                "Text": "4",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:01.7827803",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:01.7827803",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "4464dbff-37c2-44ab-a62b-41ed8018e147",
                "QuestionId": "e8f4ac1a-8277-4663-a1a8-4bdd219941af",
                "Text": "5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:02.0301414",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:02.0301414",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "biasFree",
        "QuestionId": "e1d73e67-937c-43a2-91f9-f53798b36358",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 8,
        "GroupNumber": 3,
        "Text": "The overall content of this activity was objective and free of bias.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "917725aa-415b-483d-8560-da91fdda1637",
                "QuestionId": "e1d73e67-937c-43a2-91f9-f53798b36358",
                "Text": "Yes",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:02.4953108",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:02.4953108",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "b7e1396e-193b-4666-a1c9-d472b553f006",
                "QuestionId": "e1d73e67-937c-43a2-91f9-f53798b36358",
                "Text": "No",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:02.7468916",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:02.7468916",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "biasExplanation",
        "QuestionId": "ec328c2a-f260-4510-8959-60a9469ed384",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 9,
        "GroupNumber": 3,
        "Text": "If you answered \"no\" to the previous statement, please explain why.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": false,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "9c3df703-82df-45f4-b78c-121cfc916de0",
                "QuestionId": "ec328c2a-f260-4510-8959-60a9469ed384",
                "Text": "----",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:03.1933934",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:03.1933934",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "describeGLP",
        "QuestionId": "406c2181-6852-4f96-9cfb-77ab79cb533a",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 10,
        "GroupNumber": 4,
        "Text": "Discuss current and emerging incretin-based agents and bariatric surgery for the management of obesity and related disorders in women with PCOS",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "e04ad44a-8922-4db3-a882-57d2fc65f77d",
                "QuestionId": "406c2181-6852-4f96-9cfb-77ab79cb533a",
                "Text": "1",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:03.6437386",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:03.6437386",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "5180bd09-b4ea-4a0e-a3d1-52290821da69",
                "QuestionId": "406c2181-6852-4f96-9cfb-77ab79cb533a",
                "Text": "2",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:03.9024999",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:03.9024999",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "5a5131bf-cfd2-42cc-8b7d-fd33dc0e5752",
                "QuestionId": "406c2181-6852-4f96-9cfb-77ab79cb533a",
                "Text": "3",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:04.1620052",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:04.1620052",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "2c6b2a4c-e3b6-43c8-ad5c-320bdf54cb84",
                "QuestionId": "406c2181-6852-4f96-9cfb-77ab79cb533a",
                "Text": "4",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:04.4105815",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:04.4105815",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "87867b2e-2a1a-4a46-8990-2ea79635f184",
                "QuestionId": "406c2181-6852-4f96-9cfb-77ab79cb533a",
                "Text": "5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:04.6787737",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:04.6787737",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "relevant",
        "QuestionId": "6c02a5d9-ac05-41a0-a0ba-74f0cd739106",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 11,
        "GroupNumber": 5,
        "Text": "The educational content was useful and relevant to my practice.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "a38a4494-e621-4e4c-9a46-5d9401cef71d",
                "QuestionId": "6c02a5d9-ac05-41a0-a0ba-74f0cd739106",
                "Text": "1",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:05.1434211",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:05.1434211",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "065dc358-b911-46aa-a065-acf6b2746c48",
                "QuestionId": "6c02a5d9-ac05-41a0-a0ba-74f0cd739106",
                "Text": "2",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:05.390843",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:05.390843",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ebead63f-e3cb-4c2f-b72d-c5034bda0092",
                "QuestionId": "6c02a5d9-ac05-41a0-a0ba-74f0cd739106",
                "Text": "3",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:05.6746389",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:05.6746389",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ec2ce240-3599-46f6-8e62-7d6df93809b2",
                "QuestionId": "6c02a5d9-ac05-41a0-a0ba-74f0cd739106",
                "Text": "4",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:05.9327136",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:05.9327136",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "8dfe47a8-fd3d-4878-93d0-2685a8cecf54",
                "QuestionId": "6c02a5d9-ac05-41a0-a0ba-74f0cd739106",
                "Text": "5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:06.1533605",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:06.1533605",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "educational",
        "QuestionId": "f8e50867-40e8-49c7-8596-9f610d002f12",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 12,
        "GroupNumber": 5,
        "Text": "The educational format was effective.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "3528677a-0432-48e1-b2ca-bc8f87e9ef79",
                "QuestionId": "f8e50867-40e8-49c7-8596-9f610d002f12",
                "Text": "1",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:06.6226605",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:06.6226605",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "37bc6c93-c4cb-4861-9a04-b33d3d808760",
                "QuestionId": "f8e50867-40e8-49c7-8596-9f610d002f12",
                "Text": "2",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:06.8631668",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:06.8631668",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "703c9ded-6263-415b-acd0-766f5f5b456a",
                "QuestionId": "f8e50867-40e8-49c7-8596-9f610d002f12",
                "Text": "3",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:07.1340852",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:07.1340852",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "2f510f63-40f4-4ea1-ab00-aa44d4f5a014",
                "QuestionId": "f8e50867-40e8-49c7-8596-9f610d002f12",
                "Text": "4",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:07.3465639",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:07.3465639",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "dbc9357f-a007-4a81-b5ad-265c15c9d120",
                "QuestionId": "f8e50867-40e8-49c7-8596-9f610d002f12",
                "Text": "5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:07.5776572",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:07.5776572",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "knowledge",
        "QuestionId": "293e0ec8-5c84-4120-be07-2223d3a584a8",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 13,
        "GroupNumber": 5,
        "Text": "The information provided in this presentation enhanced my knowledge and will enable me to provide better care for my patients with PCOS.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "87a8a2ef-1f41-4ff5-bfda-8b9e44f85573",
                "QuestionId": "293e0ec8-5c84-4120-be07-2223d3a584a8",
                "Text": "1",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:08.2339006",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:08.2339006",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "85f2c388-503f-42aa-9601-c4fab925fa3e",
                "QuestionId": "293e0ec8-5c84-4120-be07-2223d3a584a8",
                "Text": "2",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:08.6882441",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:08.6882441",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "efcb7305-10ab-404d-8b7a-626704fecc27",
                "QuestionId": "293e0ec8-5c84-4120-be07-2223d3a584a8",
                "Text": "3",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:08.9799304",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:08.9799304",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "9c89f544-93ef-4044-8b1d-fc7c534b5164",
                "QuestionId": "293e0ec8-5c84-4120-be07-2223d3a584a8",
                "Text": "4",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:09.2285468",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:09.2285468",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "7690a0e6-ef6b-433a-8e11-68af83cfaf46",
                "QuestionId": "293e0ec8-5c84-4120-be07-2223d3a584a8",
                "Text": "5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:09.6094581",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:09.6094581",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "confidence",
        "QuestionId": "2f718a95-f8eb-4883-b149-8a09f4676ae7",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 14,
        "GroupNumber": 5,
        "Text": "The information provided in this presentation enhanced my confidence in treating PCOS.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "1d83c011-b8d4-48b2-bd1b-79f215c749f4",
                "QuestionId": "2f718a95-f8eb-4883-b149-8a09f4676ae7",
                "Text": "1",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:10.3381149",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:10.3381149",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ff05c1ac-8aac-4e31-9c64-14bd2af5281f",
                "QuestionId": "2f718a95-f8eb-4883-b149-8a09f4676ae7",
                "Text": "2",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:10.6427268",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:10.6427268",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "c09a7953-30a5-4fc5-af5b-40c7376a378e",
                "QuestionId": "2f718a95-f8eb-4883-b149-8a09f4676ae7",
                "Text": "3",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 3,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:10.9530206",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:10.9530206",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ccf1fa45-33ad-49b2-b193-b3b22f3d81c1",
                "QuestionId": "2f718a95-f8eb-4883-b149-8a09f4676ae7",
                "Text": "4",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 4,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:11.2643546",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:11.2643546",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "ab492c35-819d-483e-88f6-7b61a03a41bc",
                "QuestionId": "2f718a95-f8eb-4883-b149-8a09f4676ae7",
                "Text": "5",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 5,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:11.5232435",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:11.5232435",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],

    },
    {
        "Alias": "unansweredQuestion",
        "QuestionId": "fe19ec26-c67b-4eda-80e0-89777d1ac109",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 15,
        "GroupNumber": 5,
        "Text": "What one question remains unanswered after having participated in this activity?",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "4ee9e131-a1c4-4527-8169-f855a220d330",
                "QuestionId": "fe19ec26-c67b-4eda-80e0-89777d1ac109",
                "Text": "---",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:12.0076537",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:12.0076537",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "infoLearned",
        "QuestionId": "f8258da7-b3f8-486e-a9dd-626f0a2d342e",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 16,
        "GroupNumber": 5,
        "Text": "Please cite one new piece of information that you learned from this activity.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "8f5e0b67-7e9e-4e08-bcfe-8c51d7a1ba3a",
                "QuestionId": "f8258da7-b3f8-486e-a9dd-626f0a2d342e",
                "Text": "--",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:12.4575312",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:12.4575312",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "relatedTopics",
        "QuestionId": "3deaf576-f233-4f5d-bd97-6188340d7e4c",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 17,
        "GroupNumber": 5,
        "Text": "What topics related to diabetes would you like to see offered in future continuing education activities?",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "806ed2a8-e106-410c-a2cd-5878c8847c62",
                "QuestionId": "3deaf576-f233-4f5d-bd97-6188340d7e4c",
                "Text": "--",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:12.9307743",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:12.9307743",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "commitment",
        "QuestionId": "9f30508f-2669-4d79-8ec8-7b70b67590e5",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 18,
        "GroupNumber": 6,
        "Text": "After participating in this activity, I am committed to changing the way I treat my patients with PCOS.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": true,
        "Type": "radiobutton",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "3826c579-0d40-4d5e-ab2d-dc53f005e609",
                "QuestionId": "9f30508f-2669-4d79-8ec8-7b70b67590e5",
                "Text": "Yes",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:13.3994997",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:13.3994997",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            },
            {
                "AnswerId": "0533cc1c-1629-4b6f-8d09-fc214c7f174c",
                "QuestionId": "9f30508f-2669-4d79-8ec8-7b70b67590e5",
                "Text": "No",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 2,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:13.6419061",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:13.6419061",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    },
    {
        "Alias": "commitmentDescription",
        "QuestionId": "3cce338f-eaf5-4d3a-9927-db20485a68a2",
        "ActivityTestId": "9252e6c7-fc19-42ec-9b01-053b0b260e8b",
        "SortOrder": 19,
        "GroupNumber": 6,
        "Text": "If yes, please describe one thing you plan to change in your practice as a result of this activity.",
        "ImageUrl": null,
        "AdditionalInfo": "",
        "IsRequired": false,
        "Type": "textarea",
        "ResponseId": null,
        "Answers": [
            {
                "AnswerId": "7fdfab0a-0c39-42c0-936a-e0c32a2f1f5a",
                "QuestionId": "3cce338f-eaf5-4d3a-9927-db20485a68a2",
                "Text": "--",
                "ImageUrl": null,
                "ImageLocationId": 0,
                "HotSpotTypeId": 0,
                "HotSpotCoords": null,
                "SortOrder": 1,
                "MatchingId": 0,
                "IsCorrectAnswer": false,
                "CreatedDate": "2023-08-31T19:00:14.0908455",
                "CreatedBy": "00000000-0000-0000-0000-000000000000",
                "ModifiedDate": "2023-08-31T19:00:14.0908455",
                "ModifiedBy": "00000000-0000-0000-0000-000000000000",
                "IsDeleted": false,
                "Culture": "en-US",
                "Type": "Unknown",
                "Status": "Approved",
                "TypeId": 0,
                "StatusId": 5,
                "Links": null
            }
        ],
    }
];