import { Action } from '@ngrx/store';
import { type } from '../util';
import { IUserStatus } from '../../models/user-status.model';

export const ActionTypes = {
  USER_STATUS_SET: type<'USER_STATUS_SET'>('USER_STATUS_SET'),
  USER_STATUS_RESET: type<'USER_STATUS_RESET'>('USER_STATUS_RESET'),
}

export class SetUserStatus implements Action {
  type = ActionTypes.USER_STATUS_SET;
  payload: IUserStatus;

  constructor(userStatus: IUserStatus) {
    this.payload = userStatus;
  }
}

export class ResetUserStatus implements Action {
  type = ActionTypes.USER_STATUS_RESET;
  payload: any;

  constructor() { }
}

export type Actions =
  SetUserStatus
  | ResetUserStatus;
