import { NgModule, APP_INITIALIZER  } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  NG_VALIDATORS,
} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { CookieModule } from 'ngx-cookie';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { MyModsComponent } from './components/mods/mods.component';
import { MyStatusComponent } from './components/my-status/my-status.component';
import { PretestComponent } from './components/pretest/pretest.component';
import { PosttestComponent } from './components/posttest/posttest.component';
import { ActivityComponent } from './components/activity/activity.component';


import { AuthService } from './services/auth/auth.service';
// import { AuthServices } from './services/auth/auth.services';

import { LoginService } from './services/login.service';
import { TrackByService } from './services/trackby.service';
import { EmailService } from './services/email.service';

import { TopicService } from './services/topic/topic.service';
import { UserService } from './services/user/user.service';
import { ActivityService } from './services/activity.service';

import { ModalLauncherService } from './services/modal-launcher/modal-launcher.service';
import { CertificateService } from './services/certificate/certificate.service';
import { AuthGuard } from './guards/auth.guard';

import { AuthContainer } from './containers/auth.container';
import { LoginContainer } from './containers/login.container';
import { RegisterContainer } from './containers/register.container';
import { ActivityContainer } from './containers/activity.container';
import { StorageContainer } from './containers/storage.container';
import { FormConverterContainer } from './components/evaluation/form-converter.container';

import { TokenInterceptor } from './interceptors/token.interceptor';


import { reducers } from './state/rootReducer';
import { CaseStudyComponent } from './components/case-study/case-study.component';
import { RegisterComponent } from './components/register/register.component';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { RationaleModalComponent } from './components/modals/rationale-modal/rationale-modal.component';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { AppRoutingModule } from './app-routing.module';
import { GoogleEventsService } from './services/google-events/google-events.service';

export function initServiceFactory(service: AuthContainer): Function{
  return () => service.load();
};

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: Provider[];
  }
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['user', 'userHistory', 'userTopic'],
    rehydrate: true,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        MyModsComponent,
        MyStatusComponent,
        PretestComponent,
        PosttestComponent,
        ActivityComponent,
        CaseStudyComponent,
        RegisterComponent,
        EvaluationComponent,
        RationaleModalComponent,
        InfoModalComponent,
        FooterComponent,
        CertificateComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MultiselectDropdownModule,
        SimpleModalModule.forRoot({ container: 'modal-container' }),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            // logOnly: environment.production,
        }),
        NgbModule,
        
        // CookieModule.forRoot(),
    ],
    providers: [
        AuthService,
        // AuthServices,
        TopicService,
        UserService,
        ActivityService,
        ModalLauncherService,
        CertificateService,
        GoogleEventsService,
        AuthGuard,
        LoginService,
        AuthContainer,
        LoginContainer,
        RegisterContainer,
        StorageContainer,
        ActivityContainer,
        TrackByService,
        EmailService,
        FormConverterContainer,
        {
          provide: APP_INITIALIZER,
          useFactory: initServiceFactory,
          deps: [AuthContainer],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router) {
   // console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
