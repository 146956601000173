<div class="modal-content">
  <div class="modal-header d-flex justify-content-between bg-primary text-white">
    <h4>Rationale</h4>
    <a (click)="closeModal()">
      <i class="fas fa-times-circle"></i>
    </a>
  </div>
  <div class="modal-body">
    <div [innerHTML]="rationale"></div>
  </div>
</div>