import * as userStatus from '../actions/user-status.action';
import { IUserStatus } from '../../models/user-status.model';

export interface UserStatusState {
  status: IUserStatus;
}

const initialState: UserStatusState = {
  status: null
};

export function userStatusReducer(state = initialState, action: userStatus.Actions): UserStatusState {
  switch (action.type) {
    case userStatus.ActionTypes.USER_STATUS_SET: return Object.assign({}, state, { status: action.payload });
    case userStatus.ActionTypes.USER_STATUS_RESET: return Object.assign({}, state, { status: null });
    default: {
      return state;
    }
  }
}
