<div class="register">
  <div class="col-md-11 mx-auto order-md-1">
    <h5 class="mt-4 mb-4 text-primary text-center">Sign up now - Complete this form to register</h5>

    <form class="needs-validation" [formGroup]="regFormGroup" [class.warning]="!regFormGroup.valid && regFormGroup.dirty" (ngSubmit)="onSubmit(regFormGroup.value)" autocomplete="off">

      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="reg-first-name">First name
            <sup>*</sup>
          </label>
          <input type="text" class="form-control" placeholder="" [formControl]="firstNameControl" id="reg-first-name" required>
          <div class="invalid-feedback">
            Valid first name is required.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="reg-last-name">Last name
            <sup>*</sup>
          </label>
          <input type="text" class="form-control" placeholder="" [formControl]="lastNameControl" id="reg-last-name" required>
          <div class="invalid-feedback">
            Valid last name is required.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="reg-email">Email
          <sup>*</sup>
        </label>
        <input type="email" class="form-control" [formControl]="emailControl" id="reg-email" placeholder="you@example.com" required>
        <small class="form-text text-muted">We'll never share your email with anyone else.</small>

        <div class="invalid-feedback">
          Please enter a valid email address.
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="reg-username">Username<sup>*</sup></label>
          <input type="text" class="form-control" [formControl]="usernameControl" id="reg-username" required>
          <div class="invalid-feedback">
            Username is required.
          </div>

        </div>
        <div class="col-md-6 mb-3">
          <label for="reg-password">Password
            <sup>*</sup>
          </label>
          <input type="password" class="form-control" [formControl]="passwordControl" id="reg-password" placeholder="" required>
          <div class="invalid-feedback">
            Password required.
          </div>
        </div>
      </div>



      <div class="row">
        <div class="col-md-6 mb-3">

          <div class="form-group">
            <label for="reg-security-question">Security Question<sup>*</sup></label>

            <select class="form-control" id="reg-security-question" [formControl]="UserSecurityQuestionIdControl" ngModel>
              <option value="" disabled>Choose a question</option>
              <option *ngFor="let option of securityQuestioOptions" [ngValue]="option.id" [id]="'security' + option.id">
                {{option.name}}
              </option>
            </select>
          </div>
          <div class="invalid-feedback">
            Please select a security question.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="reg-security-answer">Security Answer
            <sup>*</sup>
          </label>
          <input type="text" class="form-control" [formControl]="SecurityAnswerControl" id="reg-security-answer" required>
          <div class="invalid-feedback">
            Security answer required.
          </div>

        </div>

      </div>
      <!-- <div class="col-md-12 mb-3 text-center">
        <div class="form-check mb-2">
          <input class="form-check-input" type="checkbox" [formControl]="ScheduleEmailControl" id="reg-schedule-email">

          <label class="form-check-label" for="reg-schedule-email">
            Yes, I would like to receive weekly emails
          </label>
        </div>
      </div> -->
      <button type="submit" [disabled]="!regFormGroup.valid" class="btn btn-lg btn-primary btn-block">Submit</button>
      <div class="col-md-12 mt-1" *ngIf="submissionMessage">
        <p>{{submissionMessage }}</p>
      </div>
    </form>
  </div>
</div>
<br/><br/><br/>
