import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import {
  IOption,
  ISelectedAnswer,
  ITestQuestion,
  ITestRequest,
  ITopic,
  ITopicStatusRequest,
} from '../../models/topic.model';
import { IUserStatus } from '../../models/user-status.model';
import { IUser } from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { TopicService } from '../../services/topic/topic.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-pretest',
  templateUrl: './pretest.component.html',
  styleUrls: ['./pretest.component.scss'],
})
export class PretestComponent implements OnInit, OnDestroy {
  topicData: ITopic;
  questions: ITestQuestion[];
  topicId;
  questionId: number;
  testType: string;

  loading = false;
  submissionMessage: string;

  user: IUser;
  userStatus: IUserStatus;
  userCurrentTopicId: number;
  answerArray: Array<ISelectedAnswer> = [];
  isChecked = false;
  isSubmitted = false;

  private subs: Subscription[] = [];

  constructor(
    private topicService: TopicService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.subs.push(
      ...[this.subscribeToCurrentTopic()]
    );

    this.validateUserTopic();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe);
  }

  subscribeToCurrentTopic() {
    return this.route.params.subscribe(params => {
      this.topicId = parseInt(params['topicId'], 10);
      this.topicData = this.topicService.getTopicById(this.topicId);
      this.questions = this.topicData.testQuestions;
    });
  }

  subscribeToCurrentUser() {
    // return this.authService
    //   .getCurretUser()
    //   .take(1)
    //   .subscribe(user => {
    //     this.user = user.current;
    //     this.userService.getCurrentUserstatus(this.user).subscribe(status => {
    //       this.userStatus = status;
    //     });
    //   });
  }

  validateUserTopic() {
    this.userService.topicSource.take(1).subscribe(id => {
      this.userCurrentTopicId = id;
    });
    if (this.userCurrentTopicId && this.userCurrentTopicId !== this.topicId) {
      this.userService.setUserTopic(this.userCurrentTopicId);
      this.router.navigate(['/pretest', this.userCurrentTopicId]);
    }
  }

  onChange(question: ITestQuestion, questionOption: IOption) {
    this.answerArray = this.answerArray.filter(entry => {
      return entry.questionId !== question.id;
    });

    const questionEntry: ISelectedAnswer = {
      questionId: question.id,
      optionValue: questionOption.value,
    };
    this.answerArray.push(questionEntry);

    question.options = this.topicService.validateAnswer(
      question,
      questionOption
    );
  }

  resetForm() {

     this.questions.forEach((q) => {
         q.options.forEach((o) => {
             o.selected = false;
         });
     })
  }

  goToCaseStudy() {
    this.resetForm();
      this.router.navigate(['/casestudy', this.topicId]);
  }

  validateQuestions() {
    this.questions = this.topicService.validateQuestionOptions(this.questions);
  }

  onSubmit() {
    this.isSubmitted = true;
    this.validateQuestions();
    const formSubmit: ITestRequest = {
        TopicId: this.topicData.TopicID,
        answers: this.answerArray.map(a => a.optionValue),
        date: new Date().toString(),
        user: {
          email: "",
          score: this.topicService.calculateScore(this.questions),
          userAccountId: this.user.userAccountID,
          username: this.user.username,
        },
      },
      topicStatusUpdate: ITopicStatusRequest = {
        TopicId: this.topicData.TopicID,
        Status: 'Incomplete',
        user: this.user,
      };

    this.topicService
      .submitTopicTest(formSubmit, this.user.Token, 'Pre-Activities')
      .subscribe(
        data => {
          this.topicService.submitTopicStatus(topicStatusUpdate).subscribe(
            data => {
              this.userService.updateUserTopicStatusByTopicId(
                this.userStatus,
                topicStatusUpdate.TopicId,
                'Active'
              );
              this.authService.updateUserToken(data, this.user);
               this.resetForm();
              this.submissionMessage =
                'Success!';
               this.router.navigate(['/casestudy', this.topicId]);

            },
            error => {
              console.log('error updating user status');
              return Observable.throw(error);
            }
          );
        },
        error => {
          console.log('error submitting pretest');
          this.submissionMessage =
            'Sorry, there was an error submitting your test.  Please try again.';
          return Observable.throw(error);
        }
      );
  }
}
