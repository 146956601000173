import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare let gtag: (action: string, category: string, label: object) => void;

@Injectable()
export class GoogleEventsService {
  constructor() { }

  trackGaEvent(action: string, category: string, label: any) {
    // gtag('event', action, {
    //   event_category: category,
    //   event_label: label
    // });
    // console.log('trackGaEvent fired 🔥');
  }

  trackGaConversion(conversionId: string) {
    // console.log(environment.googleTags.adsId);
    // console.log(conversionId);
    // gtag('event', 'conversion', {
    //   send_to: `${environment.googleTags.adsId}/${conversionId}`,
    // });
    // console.log('trackGaConversion fired 🔥');
  }

  // googleAnalyticsPageView(pageTitle, pageUrl) {
  //   gtag('config', environment.googleAnalytics, {
  //     page_title: `${pageTitle} | ${environment.googlePageTitle}`,
  //     page_path: pageUrl,
  //     page_location: window.location.href,
  //   });
  // }

  // googleAnalyticsTrackEvent(action: string, category: string, label: string) {
  //   gtag('event', action, {
  //     send_to: environment.googleAnalytics,
  //     event_category: category,
  //     event_label: label,
  //   });
  // }

  // IMNE will send you the conversion ID for the specific page view they want to fire a conversion for viewing a page or click behavior
  adWordsConversion(conversionId) {
    // gtag('event', 'conversion', {
    //   send_to: `${environment.googleAdwords}/${conversionId}`,
    // });
  }

}
