declare var window: any;


export const environment = {
  production: false,
  siteName: 'PCOs',
  appUrl: 'http://localhost:4233/',
  url: 'http://localhost:4233/',

  synApi: {
    appUrl: 'http://localhost:4233/',
    url: 'http://localhost:4233/',
    appId: `6702CF76-46C3-48D5-993D-71CCCA506E31`, //needs to be replaced with real id
    key: `lgsiSg`,
    signature: `glsiSg`,
    forgotPasswordTokenLifetime: 86400,
    authUrl: `https://app.synaptik.xyz/api/v1/sdsg/`,
    //authUrl: `http://localhost:16395/v1/sdsg/`,
    version: 1,
  },
  gameApi: {
  },
  activityApi: {
    url: 'https://activities.synaptik.xyz/api/v1/lgsiSg/',
    //url: 'http://localhost:44313/v1/lgsiSg/',
    activityId: 'c27c71ed-be2d-46ae-be0f-6abe4a7d8321', //not sure thats the right id

  },
  googleTags:  {
    analyticsId: '',
    adsId: '',
    meetingConversionId: ''
  },
  activity: {
    id: 4,
    moduleId: 5,
    topics: [12, 13, 14], //temp using the snap facts ids
    title: 'PCOS',
    url: 'staging.snapfacts.synaptik.xyz',
  },
};