<nav class="navbar navbar-expand-sm navbar-dark bg-gradient-dark">
  <div class="container">
    <ul  *ngIf="isLogged" class="navbar-nav justify-content-between ">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/mystatus']">CME Status
        </a>
      </li>
      <li style="color:white;position:relative;top:8px;">
        |
      </li>
      <li class="nav-item">
        <!-- <a class="nav-link" (click)="onShowInfoModal($event)">CME/CE Information</a> -->
        <a class="nav-link" (click)="onShowInfoModal($event)">CME/CE Information</a>
      </li> 
      <li style="color:white;position:relative;top:8px;">
        |
      </li>
      <li class="nav-item">
        <a class="nav-link" href="mailto:info@imne.com" target="_blank">Contact Us</a>
      </li> 
      <li style="color:white;position:relative;top:8px;">
        |
      </li>
      <li class="nav-item">
        <a *ngIf="!isLogged" class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/login']">Login</a>
        <a *ngIf="isLogged" class="nav-link" (click)="logout()">Logout</a>
      </li>
    </ul>
    <ul *ngIf="isLogged" class="navbar-nav justify-content-between ">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/']">HOME
        </a>
      </li>
     
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/mymods']">MODULES</a>
      </li> 
   
      <li class="nav-item">
        <a class="nav-link" href="https://imne.com">IMNE</a>
      </li> 
     
    </ul>
  </div>
</nav>

<header class="global-header img-fluid w-100">
  <!-- <div class="container w-100">
    <img class="img-fluid " src="assets/images/header/header-bg.png"> 
  </div> -->
</header>

<!--  <li class="nav-item">
        <a *ngIf="!isLogged" class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/login']">Login</a>
        <a *ngIf="isLogged" class="nav-link" (click)="logout()">Logout</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/mystatus']">My Status</a>
      </li>
      
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="additional-resources" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">Additional Resources</a>
        <div ngbDropdownMenu aria-labelledby="additional-resources">
          <a href="assets/pdf/SnapFacts_Abbreviations.pdf" target="_blank" class="dropdown-item">Abbreviations</a>
        </div>
      </li>-->