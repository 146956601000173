import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { User, UserLogin, UserLogout, UserActivity } from 'src/app/models';
import { StorageContainer } from 'src/app/containers/storage.container';
import { LoginService } from '../services/login.service';
import { LoggerService } from '../services/logger.service';
import { ActivityContainer } from 'src/app/containers/activity.container';

@Injectable()
export class LoginContainer {

    private currentUserSubject = new BehaviorSubject<User>(null);
    public currentUser = this.currentUserSubject.asObservable();

    private showLoginModalSubject = new BehaviorSubject<boolean>(false);
    public showLoginModal = this.showLoginModalSubject.asObservable();

    constructor(
        private router: Router,
        private storageContainer: StorageContainer,
        private loginService: LoginService,
        private logger: LoggerService,
        private activityContainer: ActivityContainer,

    ) {
        this.setUser();
    }

    setUser() {
        this.storageContainer.getStorageItem('user')
        .subscribe((user: User) => {
            this.updateUser(user);
           // this.updateShowLogin(user == null);
        });
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    isLoggedIn(): boolean {
        return this.currentUserValue != null;
    }

    updateShowLogin(show: boolean) {
        this.showLoginModalSubject.next(show);
    }

    updateUser(user: User) {
        this.storageContainer.setStorageItem('user', user);
        this.currentUserSubject.next(user);
    }

    clearUser() {
        this.storageContainer.deleteStorageItem('user');
        this.currentUserSubject.next(null);
    }

    endUserSession() {
        if (!this.currentUser || !this.currentUserValue || !this.currentUserValue.SynUserId)
        
        {
            this.clearUser();
            this.router.navigate([ '/' ]);
            return;
        }


        const logoutInfo: UserLogout = {
            ApplicationId: environment.synApi.appId,
            UserId: this.currentUserValue.SynUserId,
            IpAddress: '127.0.0.1'
        };

        this.loginService.logout(logoutInfo)
            .pipe(take(1))
            .subscribe(() => {
                this.clearUser();
                this.router.navigate(['/']);
            },
            error => {
                this.logger.logError(error);
            });
    }

    refreshActivityUserStatus(user: User) {
        this.loginService.getActivityUserStatus(user.SynUserId).pipe(take(1))
            .subscribe((userStatus: UserActivity) => {
                userStatus.Level = '0';
                user.ActivityStatus = userStatus;
                this.updateUser(user);
            });
    }

}