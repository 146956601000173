import { Action } from '@ngrx/store';
import { type } from '../util';
import { IUser } from '../../models/user.model';

export const ActionTypes = {
  USER_SET: type<'USER_SET'>('USER_SET'),
  USER_RESET: type<'USER_RESET'>('USER_RESET'),
}

export class SetUser implements Action {
  type = ActionTypes.USER_SET;
  payload: IUser;

  constructor(user: IUser) {
    this.payload = user;
  }
}

export class ResetUser implements Action {
  type = ActionTypes.USER_RESET;
  payload: any;

  constructor() { }
}

export type Actions =
  SetUser
  | ResetUser;
