import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ActivityComponent } from './components/activity/activity.component';
import { MyStatusComponent } from './components/my-status/my-status.component';
import { MyModsComponent } from './components/mods/mods.component';
import { PretestComponent } from './components/pretest/pretest.component';
import { PosttestComponent } from './components/posttest/posttest.component';
import { CaseStudyComponent } from './components/case-study/case-study.component';
import { EvaluationComponent } from './components/evaluation/evaluation.component';

import { AuthGuard } from './guards/auth.guard';
import { RegisterComponent } from './components/register/register.component';
const routes: Routes = [
  { path: '', component: ActivityComponent },
  { path: 'login', component: LoginComponent },
  { path: 'activity', component: ActivityComponent, canActivate: [AuthGuard] },
  { path: 'mystatus', component: MyStatusComponent, canActivate: [AuthGuard] },
  { path: 'mymods', component: MyModsComponent, canActivate: [AuthGuard] },
  { path: 'pretest/:topicId', component: PretestComponent },
  { path: 'posttest/:topicId', component: PosttestComponent },
  { path: 'casestudy/:topicId', component: CaseStudyComponent },
  { path: 'evaluation/:topicId', component: EvaluationComponent },
  { path: 'register', component: RegisterComponent },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
