import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  user$ = this.authService.user$;
  isLoggedIn;

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   // console.log('AAAAAAAAAA');
    if (!!localStorage.getItem('user'['current']) === null) {
      // logged in so return true
      //console.log('yes');
      return true;
    } else {
      //this.router.navigate(['/login']);
      return true;

      
    }
  }
}
