// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { environment as local } from './environment.local';
import { environment as dev } from './environment.dev';
// import { environment as prod } from './environment.prod';
declare var window: any;

const localHost = [ 'localhost', '127.0.0.1', '0.0.0.0', '10.10.0.216', '192.168.0.8', '192.168.0.11', '192.168.170.63' ];
const customHost = [ '192.168.2.1' ];
const devHost = [ '' ];
const prodHost = [ '' ];

let currentEnv = null;

function resolveEnvironment() {
  if (!!currentEnv) {
    return currentEnv;
  }

  if (hostnameMatches(localHost)) {
    currentEnv = local;
  } else if (hostnameMatches(devHost)) {
    currentEnv = dev;
  } else if (hostnameMatches(prodHost)) {
    // currentEnv = prod;
  } else {
    currentEnv = dev;
  }
  return currentEnv;
}

function hostnameMatches(partialHostnames) {
  const hostname = window.location.hostname;
  return partialHostnames.filter((url) => hostname.indexOf(url) > -1).length > 0;
}


export const environment = resolveEnvironment();
