import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { User, UserLogin, UserLogout, UserRegistration, UserResetPassword, UserActivityRegistration, UserActivity, UserClaim } from 'src/app/models';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    test:UserActivity;
    apiData = environment.synApi;
    activityApiData = environment.activityApi;

    constructor(
        private http: HttpClient,
    ) { }

    getUserByEmail(email: string): Observable<User> {
        return this.http.get<User>(`${ this.apiData.authUrl}User/Email?userString=${email}`);
    }

    login(creds: UserLogin): Observable<User> {
        return this.http.post<User>(`${ this.apiData.authUrl }User/Login`, creds);
    }

    logout(userInfo: UserLogout): Observable<boolean> {
        return this.http.post<boolean>(
            `${ this.apiData.authUrl }User/Logout`, userInfo);
    }

    register(user: UserRegistration): Observable<User> {
        return this.http.post<User>(`${ this.apiData.authUrl }User/Register?overrideKey=${ this.apiData.key }`, user);
    }

    registerActivityUser(user: UserActivityRegistration): Observable<UserActivityRegistration> {
        return this.http.post <UserActivityRegistration>(`${this.activityApiData.url}User`, user);
    }

    getActivityUserStatus(synUserId: string): Observable<UserActivity> {
       return this.http.get<UserActivity>(`${ this.activityApiData.url}User/Status?activityId=${this.activityApiData.activityId}&userId=${synUserId}`)
    }

    resetPassword(passwordReset: UserResetPassword): Observable<User> {
        return this.http.post<User>(`${this.apiData.authUrl}User/ResetPassword?applicationId=${ environment.synApi.appId }`, passwordReset);
    }

    setForgotPasswordCode(user: User): Observable<User> {
        return this.http.post<User>(`${this.apiData.authUrl}User/ForgotPassword/Code?tokenLifetime=1${ this.apiData.forgotPasswordTokenLifetime }`, user);
    }

    pingUser(id: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.apiData.authUrl }User/Ping?userId=${ id }&applicationId=${ this.apiData.appId }`, {});
    }

    checkUniqueEmail(email: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.apiData.authUrl }User/UniqueEmail?email=${ email }`, null);
    }

    addOrUpdateUserClaims(claims: UserClaim[], userId: string): Observable<User> {
        return this.http.post<User>(`${ this.apiData.authUrl }User/Claims?userId=${ userId }`, claims);
    }

}
