import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ICertificateInfo } from '../../models/settings.model';
import { certImageUris, SignatureImg } from '../../data/settings';
// import * as pdfMake from 'pdfmake/build/pdfmake.js';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as moment from 'moment';

@Injectable()
export class CertificateService {
  // activity name
  // activity url
  // user first/last, etc
  // date
  // cert type
  // credit hoursthis.pdfMake = require('pdfmake/build/pdfmake.js');

  activityInfo = environment.activity;
  newContent;
  pageSize = 'LETTER';
  pageOrientation = 'landscape';
  pageMargin = [70, 80, 30, 0];
  bgUri = certImageUris;
  signatureUri = SignatureImg;
  bgUriWidth = 600;
  activityTitle = this.activityInfo.title;
  activityUrl = this.activityInfo.url;
  footerMargin = [0, 60, 0, 0];
  footerText = ``;
  signatureDetails = `Steve Weinman, RN, CHCP \n Director of Accreditation`;
  headerStyle = {
    fontSize: 22,
    alignment: 'center',
    color: '#111',
    bold: true,
  };
  paragraphStyle = {
    fontSize: 13,
    alignment: 'center',
    color: '#111',
  };
  footerStyle = {
    fontSize: 10,
    alignment: 'center',
    color: '#111',
  };

  evalDate = new Date();
  days = new Array(
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  );
  months = new Array(
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  );
  date = (this.evalDate.getDate() < 10 ? '0' : '') + this.evalDate.getDate();
  evalDateString = this.days[this.evalDate.getDay()] +
  ', ' +
  this.months[this.evalDate.getMonth()] +
  ' ' +
  this.date +
  ', ' +
  this.evalDate.getFullYear();

  buildCertificateTemplate(certType) {
    certType = certType.toLowerCase();
  }

  getCeCertificateTemplate(baseTemplate) {}

  getCertificateDate(dateTime: Date) {
    return moment(new Date(dateTime)).format('MM/DD/YY');
  }

  getCertificateDateTime(dateTime: Date, isUtcDateTime: boolean) {
    let certTime;
    if (isUtcDateTime) {
      const dateOffset = new Date(dateTime).getTimezoneOffset();
      certTime = moment(new Date(dateTime))
        .subtract(dateOffset, 'minutes')
        .format('h:m A');
    } else {
      certTime = moment(new Date(dateTime))
        .local()
        .format('h:mm A');
    }

    return certTime;
  }

  getCmeCertificateTemplate() {}

  getAttendanceTemplate() {}
}
