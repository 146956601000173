import { Component, OnDestroy, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import { CMEInfo } from '../../../data/settings';
import { IInfoModal } from '../../../models/modal.model';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: [ './info-modal.component.scss' ]
})
export class InfoModalComponent extends SimpleModalComponent<IInfoModal, null> implements OnInit, OnDestroy {

  infoContent = CMEInfo;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    //super.ngOnDestroy();
  }

closeModal() {
    this.close();
  }

}
