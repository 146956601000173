import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ITopic } from '../../models/topic.model';
import { IUserStatus } from '../../models/user-status.model';
import { IUser } from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { TopicService } from '../../services/topic/topic.service';
import { UserService } from '../../services/user/user.service';
import { MyStatusOverview } from '../../data/settings';

export interface ITopicStatus {
  TopicID: number;
  icon?: string;
  caseTitle: string;
  caseDescription: string;
  status: string;
}

@Component({
  selector: 'app-my-mods',
  templateUrl: './mods.component.html',
  styleUrls: ['./mods.component.scss'],
})
export class MyModsComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  topicData: ITopic[];
  topics: ITopicStatus[];
  user: IUser;
  userStatus: IUserStatus;
  overview = MyStatusOverview;
  topicId;
  constructor(
    private topicService: TopicService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.setTopicStatus();
    this.subs.push(
      ...[
        //this.subscribeToCurrentUser(),
        //this.subscribeToUserStatus(),
        this.subscribeToUserTopic(),
      ]
    );
//console.log(this.topicData);

  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe);
  }

  subscribeToUserTopic() {
    return this.userService.getStoredUserStatus().subscribe(s => {
      const currentStatus = this.userService.getCurrentUserTopic(s.status);
      if (currentStatus) {
        this.topicId = currentStatus.TopicID;
      }
      return this.topicId;
    });
  }

  subscribeToCurrentUser() {
    return this.authService
      .getCurretUser()
      .take(1)
      .subscribe(user => {
        this.user = user.current;
      });
  }

  subscribeToUserStatus() {
    // return this.userService
    //   .getCurrentUserstatus(this.user)
    //   .take(1)
    //   .subscribe(s => {
    //     console.log(s);
    //   });
  }



  getStatusText(topic: ITopicStatus) {
    const topicStatus = topic.status.toLowerCase();
    let status = '';

    switch (topicStatus) {
      case 'completed':
      case 'complete':
        status = 'Complete';
        break;

      case 'incomplete':
      case 'active':
        status = 'Active';
        break;

      default:
        status = 'Coming Soon';
    }

    return status;
  }

  goToTopic(topic: ITopicStatus) {
    if (topic.status === 'Active') {
      this.router.navigate(['/pretest', topic.TopicID]);
    }
  }

  formatStatusClass(status: string) {
    status = status.toLowerCase();
    status = status.replace(' ', '-');
    return status;
  }

  setTopicStatus() {
   // status: IUserStatus
   // console.log(status.List);
    //let corrected 
    const topicList = [];
    //status.List.map(s => {
     // console.log(s);
      let topic = this.topicService.getTopicById(12);
      // if (s.TopicID) {
        let currentStatus = "Incomplete";//s.Status.toLowerCase().trim();
        let topicInfo = {
          TopicID: topic.TopicID,
          icon: topic.icon ? topic.icon : '',
          caseTitle: topic.caseTitle,
          caseDescription: topic.caseDescription,
          status: currentStatus,
        };
        topicInfo.status = 'Active';
        topicList.push(topicInfo);
         topic = this.topicService.getTopicById(13);
        // if (s.TopicID) {
           currentStatus = "Incomplete";//s.Status.toLowerCase().trim();
           topicInfo = {
            TopicID: topic.TopicID,
            icon: topic.icon ? topic.icon : '',
            caseTitle: topic.caseTitle,
            caseDescription: topic.caseDescription,
            status: currentStatus,
          };
          topicInfo.status = 'Active';
          topicList.push(topicInfo);
          topic = this.topicService.getTopicById(14);
          // if (s.TopicID) {
             currentStatus = "Incomplete";//s.Status.toLowerCase().trim();
             topicInfo = {
              TopicID: topic.TopicID,
              icon: topic.icon ? topic.icon : '',
              caseTitle: topic.caseTitle,
              caseDescription: topic.caseDescription,
              status: currentStatus,
            };
            topicInfo.status = 'Active';
            topicList.push(topicInfo);  
        // if (
        //   (s.TopicAvailable && currentStatus === 'incomplete') ||
        //   (s.TopicAvailable && currentStatus === 'not started')
        // ) {
        //   topicInfo.status = 'Active';
        // } else {
        //   if (currentStatus === 'complete' || currentStatus === 'completed') {
        //     topicInfo.status = 'Complete';
        //   } else {
        //     topicInfo.status = 'Active';
        //   }
        // }

        // if (currentStatus === 'complete' || currentStatus === 'completed') {
        //   topicInfo.status = 'Complete';
        // } else {
        //   console.log('?: ' + s.TopicAvailable + topic);
        //   topicInfo.status = 'Coming Soon';
        // }

        // if (currentStatus === 'complete' || currentStatus === 'completed') {
        //   topicInfo.status = 'Complete';
        //  } else if (currentStatus === 'complete') {
        //   /* do the right thing */
        //  } else {
        //   /* do the right thing */
        //  }

        //topicList.push(topicInfo);
      //}
    //});
    this.topics = topicList;
  }
}
