import { Injectable } from '@angular/core';
import { Module, ActivityTest } from 'src/app/models';

@Injectable()
export class TrackByService {

    module(index: number, module: Module) {
        return module.ModuleId;
    }

    activityTest(index: number, activityTest: ActivityTest) {
        activityTest.ActivityTestId
    }
}