import { ITopic } from '../models/topic.model';
export const TopicData: Array<ITopic> = [
  {
    id: 1,
    ModuleId: 5,
    TopicID: 12,
    icon: 'assets/images/character-icons/character1.png',
    icontwo: 'assets/images/character-icons/character1m.png',
    title:
      'More Cycles in Women’s Health—Explaining The Complicated Relationship Between Obesity and PCOS',
    learningObjective:
      '',
    pretestOverview:
      'To receive continuing medical education (CME)/continuing education (CE) credit, please complete the following preactivity assessment. There is only 1 correct answer per question. Please select the most appropriate answer for each question.',
    posttestOverview:
      'To receive continuing medical education (CME)/continuing education (CE) credit, please complete the following post-activity assessment. There is only 1 correct answer per question. Please select the most appropriate answer for each question. If needed, you will have the opportunity to change your answers and make additional attempts to achieve a passing score.',
    testQuestions: [
      {
        id: 1,
        label:
          'In women with PCOS, the prevalence of obesity is________.',
        answerId: 3,
        rationale: '',
        options: [
          {
            id: 1,
            label: '10% to 30%',
            value: 1,
          },
          {
            id: 2,
            label: '30% to 50%',
            value: 2,
          },
          {
            id: 3,
            label: '50% to 80%',
            value: 3,
          },
          {
            id: 4,
            label: '80% to 95%',
            value: 4,
          },
        ],
      },
      {
        id: 2,
        label:
          'What percentage of patients would like their HCP to discuss weight with them?',
        answerId: 3,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              '18%',
            value: 1,
          },
          {
            id: 2,
            label:
              '48%',
            value: 2,
          },
          {
            id: 3,
            label:
              '68%',
            value: 3,
          },
          {
            id: 4,
            label:
              '88%',
            value: 4,
          },
        
        ],
      },
      
      {
        id: 3,
        label:
          'Which of the following statements best describes the relationship between anovulatory fertility and BMI?',
        answerId: 2,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Risk for anovulatory fertility increases with increasing BMI, starting from a BMI of 17',
            value: 1,
          },
          {
            id: 2,
            label:
              'Risk for anovulatory fertility increases with increasing BMI, starting from a BMI of slightly below 25',
            value: 2,
          },
          {
            id: 3,
            label:
              'Risk for anovulatory fertility decreases with increasing BMI, until a BMI of 30',
            value: 3,
          },
          {
            id: 4,
            label:
              'Anovulatory fertility is not associated with BMI',
            value: 4,
          },
        
        ],
      },

      {
        id: 4,
        label:
          'PCOS increases the risk of which adverse pregnancy outcome?',
        answerId: 4,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Type 2 diabetes',
            value: 1,
          },
          {
            id: 2,
            label:
              'Osteoporosis',
            value: 2,
          },
          {
            id: 3,
            label:
              'Intrauterine growth restriction',
            value: 3,
          },
          {
            id: 4,
            label:
              'Preeclampsia',
            value: 4,
          },
        
        ],
      },
      {
        id: 5,
        label:
          'In a meta-analysis of 23 trials of women with PCOS, which weight loss therapy provided the greatest reduction in BMI?',
        answerId: 1,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Liraglutide + metformin',
            value: 1,
          },
          {
            id: 2,
            label:
              'Orlistat',
            value: 2,
          },
          {
            id: 3,
            label:
              'Liraglutide',
            value: 3,
          },
          {
            id: 4,
            label:
              'Metformin',
            value: 4,
          },
        
        ],
      },
    ],
    caseQuestions: [
    ],
    caseTitle: '',
    caseDescription: '',
    caseContent: [
    ],
  },


  {
    id: 2,
    ModuleId: 5,
    TopicID: 13,
    icon: 'assets/images/character-icons/character2.png',
    icontwo: 'assets/images/character-icons/character2m.png',

    title:
    'Discussing Weight With Women—Approaching the Topic of Weight Management',
    learningObjective:
      '',
    pretestOverview:
      'To receive continuing medical education (CME)/continuing education (CE) credit, please complete the following preactivity assessment. There is only 1 correct answer per question. Please select the most appropriate answer for each question.',
    posttestOverview:
      'To receive continuing medical education (CME)/continuing education (CE) credit, please complete the following post-activity assessment. There is only 1 correct answer per question. Please select the most appropriate answer for each question. If needed, you will have the opportunity to change your answers and make additional attempts to achieve a passing score.',
    testQuestions: [
      {
        id: 1,
        label:
          'Which of the following is the correct way to initiate a conversation about weight?',
        answerId: 3,
        rationale: '',
        options: [
          {
            id: 1,
            label: 'Ask the individual why they are not concerned about addressing their weight',
            value: 1,
          },
          {
            id: 2,
            label: 'Ask the individual to step onto scales in your office so that you can have an informed discussion about their weight',
            value: 2,
          },
          {
            id: 3,
            label: 'Ask the individual’s permission to discuss weight',
            value: 3,
          },
          {
            id: 4,
            label: 'Tell the individual about the weight management interventions they are indicated for and ask which ones they would be open to trying',
            value: 4,
          },
        ],
      },
      {
        id: 2,
        label:
          'For a patient with obesity (BMI 32.1) and PCOS, what amount of weight loss is required for meaningful health improvements in PCOS?',
        answerId: 4,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              '0% to 3%',
            value: 1,
          },
          {
            id: 2,
            label:
              '3% to 5%',
            value: 2,
          },
          {
            id: 3,
            label:
              '5% to 10%',
            value: 3,
          },
          {
            id: 4,
            label:
              '5% to 15%',
            value: 4,
          },
        
        ],
      },
      
      {
        id: 3,
        label:
          'Which sequence describes the approach to motivational interviewing?',
        answerId: 2,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Explain, Ask, Listen',
            value: 1,
          },
          {
            id: 2,
            label:
              'Ask, Listen, Summarize',
            value: 2,
          },
          {
            id: 3,
            label:
              'Educate, Listen, Summarize',
            value: 3,
          },
          {
            id: 4,
            label:
              'Ask, Understand, Educate',
            value: 4,
          },
        
        ],
      },

      {
        id: 4,
        label:
          'What percentage of people with prediabetes go on to develop type 2 diabetes?',
        answerId: 3,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              '10%',
            value: 1,
          },
          {
            id: 2,
            label:
              '30%',
            value: 2,
          },
          {
            id: 3,
            label:
              '50%',
            value: 3,
          },
          {
            id: 4,
            label:
              '70%',
            value: 4,
          },
        
        ],
      },
      {
        id: 5,
        label:
          'Which of the following is correct for obesity management goals?',
        answerId: 1,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Goals should aim to improve health and well-being',
            value: 1,
          },
          {
            id: 2,
            label:
              'Short-term goals are the recommended approach',
              value: 2,
          },
          {
            id: 3,
            label:
              'Goals should be reassessed and adjusted annually',
            value: 3,
          },
          {
            id: 4,
            label:
              'Goals should focus on achieving percentage weight loss',
            value: 4,
          },
        
        ],
      },
    ],
    caseQuestions: [
    ],
    caseTitle: '',
    caseDescription: ``,
    caseContent: [
    ],
  },


  {
    id: 3,
    ModuleId: 5,
    TopicID: 14,
    icon: 'assets/images/character-icons/character3.png',
    icontwo: 'assets/images/character-icons/character3m.png',

    title:
    'New and Emerging Solutions – Incretin-based Therapies for Weight Management in Women With PCOS',
     
    learningObjective:
      '',
    pretestOverview:
      ' ',
    posttestOverview:
      '',
    testQuestions: [
      {
        id: 1,
        label:
          'Which one of the following metabolic changes occurs in response to weight loss and promotes weight regain?',
        answerId: 2,
        rationale: '',
        options: [
          {
            id: 1,
            label: 'Decrease in circulating levels of appetite-stimulating hormone ghrelin',
            value: 1,
          },
          {
            id: 2,
            label: 'Decrease in satiety hormone signalling',
            value: 2,
          },
          {
            id: 3,
            label: 'Increase in energy expenditure',
            value: 3,
          },
          {
            id: 4,
            label: 'Decrease in brain response to food cues in homeostatic and reward regions',
            value: 4,
          },
        ],
      },
      {
        id: 2,
        label:
          'Naltrexone/bupropion belongs to which drug class?',
        answerId: 4,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Sympathomimetic/anti-seizure medication',
            value: 1,
          },
          {
            id: 2,
            label:
              'GLP-1 receptor agonist/SGLT2 inhibitor',
            value: 2,
          },
          {
            id: 3,
            label:
              'Gastrointestinal lipase inhibitor/DPP-4 inhibitor',
            value: 3,
          },
          {
            id: 4,
            label:
              'Opioid antagonist/antidepressant',
            value: 4,
          },
        
        ],
      },
      
      {
        id: 3,
        label:
          'Which BMI threshold must be met to indicate a GLP-1 receptor agonist pharmacotherapy in a person with obesity and PCOS?',
        answerId: 3,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              '≥22',
            value: 1,
          },
          {
            id: 2,
            label:
              '≥25',
            value: 2,
          },
          {
            id: 3,
            label:
              '≥27',
            value: 3,
          },
          {
            id: 4,
            label:
              '≥30',
            value: 4,
          },
        
        ],
      },

      {
        id: 4,
        label:
          'Which of the following are the most common adverse events associated with phentermine/topiramate?',
        answerId: 1,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              'Paresthesia, constipation, dry mouth, headache, upper respiratory tract infection',
            value: 1,
          },
          {
            id: 2,
            label:
              'Gastrointestinal adverse events (nausea, diarrhea, vomiting, constipation, dyspepsia, abdominal pain), headache',
            value: 2,
          },
          {
            id: 3,
            label:
              'Gastrointestinal adverse events (nausea, vomiting), headache, dizziness, insomnia, dry mouth',
            value: 3,
          },
          {
            id: 4,
            label:
              'Abdominal pain/discomfort, oily spotting/stool, fecal urgency',
            value: 4,
          },
        
        ],
      },
      {
        id: 5,
        label:
          'Which of the following criteria indicates a need to discontinue liraglutide and consider a different medication or treatment?',
        answerId: 2,
        rationale:'',
        options: [
          {
            id: 1,
            label:
              '< 4% weight loss in 12 weeks',
            value: 1,
          },
          {
            id: 2,
            label:
              '< 4% weight loss in 16 weeks',
            value: 2,
          },
          {
            id: 3,
            label:
              '< 5% weight loss in 12 weeks',
            value: 3,
          },
          {
            id: 4,
            label:
              '< 5% weight loss in 16 weeks',
            value: 4,
          },
        
        ],
      },
    ],
    caseQuestions: [
    ],
    caseTitle: '',
    caseDescription:
      '',
    caseContent: [
    ],
  },


];
