import * as userTopic from '../actions/topic.action';

export interface UserTopicState {
  topicId: number;
}

const initialState: UserTopicState = {
  topicId: null
};

export function userTopicReducer(state = initialState, action: userTopic.Actions): UserTopicState {
  switch (action.type) {
    case userTopic.ActionTypes.USER_TOPIC_SET: return Object.assign({}, state, { topicId: action.payload });
    case userTopic.ActionTypes.USER_TOPIC_RESET: return Object.assign({}, state, { topicId: null });
    default: {
      return state;
    }
  }
}
